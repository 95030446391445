import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { UserDO } from '../app/models/userDO';
import { FeatureDO } from '../app/models/featureDO';
import { VoteDO } from 'src/app/models/voteDO';

// export interface Vote {
//   userId: any;
//   id: any;
//   value: number;
// }

@Injectable({
  providedIn: 'root',
})
export class VotingService {
  private voteCollection: AngularFirestoreCollection<VoteDO>;

  constructor(private afs: AngularFirestore) {
    this.voteCollection = afs.collection<VoteDO>('voting');
  }

  getUserVotes(user: UserDO) {
    const votingRef = this.afs.collection('voting', (ref) =>
      ref.where('userId', '==', user.userId)
    );
    return votingRef.valueChanges();
  }

  getFeatureVotes(feature: FeatureDO) {
    const votingRef = this.afs.collection('voting', (ref) =>
      ref.where('featureId', '==', feature.id)
    );
    return votingRef.valueChanges();
  }

  // Create or update Vote
  setVote(feature: FeatureDO, value: number, userId?: string) {
    // Vote document data
    if (userId) {
      const vote = { userId: userId, featureId: feature.id, value: value };
      const voteDoc = `${vote.userId}_${vote.featureId}`;
      return this.voteCollection
        .doc(voteDoc)
        .set(vote)
        .catch((err) => {
          console.log(err);
        });
    } else {
      const vote = { userId: 'anon', featureId: feature.id, value: value };
      const voteDocAnon = `${vote.userId}_${vote.featureId}`;
      return this.voteCollection
        .doc(voteDocAnon)
        .set(vote)
        .catch((err) => {
          console.log(err);
        });
    }

    // Custom doc ID for relationship
    // const votePath = `votes/${vote.userId}_${vote.featureId}`;

    // Set the data, return the promise
    // return this.afs.doc(votePath).set(vote);
  }
}
