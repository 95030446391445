import { AbstractControl } from '@angular/forms';
import { FirestoreService } from '../services/firestore.service';
import { map, debounceTime, switchMap, first } from 'rxjs/operators';
import { isEmpty } from 'lodash';

export class ValidateDomainNotTaken {
  static createValidator(fs: FirestoreService) {
    return (control: AbstractControl) => {
      return control.valueChanges.pipe(
        debounceTime(400),
        switchMap((sm) =>
          fs.getSettingsFromDomain(control.value).pipe(
            map((res) => {
              const test = isEmpty(res);
              return test ? control.setErrors(null) : control.setErrors({ domainTaken: true });
            }),
            first()
          )
        )
      );
    };
  }
}
