import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UserDO } from 'src/app/models/userDO';
import { FormBuilder } from '@angular/forms';
import { FirestoreService } from '../../../../services/firestore.service';
import { StorageService } from '../../../../services/storage.service';
import { SettingsDO } from 'src/app/models/settingsDO';
import { tap, map, finalize } from 'rxjs/operators';
import { UserDataService } from '../../../../services/user-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
})
export class CompanyInfoComponent implements OnInit, OnDestroy {
  userDataSubscription: Subscription;
  settings: SettingsDO;
  user: UserDO;

  selectedFiles: FileList;
  currentUpload: number;

  companyInfoForm = this.fb.group({
    displayName: [null],
  });

  constructor(
    private fb: FormBuilder,
    private fs: FirestoreService,
    private storageService: StorageService,
    private userDataService: UserDataService
  ) {
    this.userDataSubscription = this.userDataService.getUserData().subscribe((user) => {
      console.log(user);
      this.settings = user.settings['0'];
    });
  }

  ngOnInit() {
    if (!this.settings.logo) {
      this.settings.logo = 'https://via.placeholder.com/400x200?text=Your+Company+Logo';
    }
  }

  getLogo() {
    if (this.settings.logo) {
      return this.settings.logo;
    } else {
      return 'https://via.placeholder.com/400x200?text=Your+Company+Logo';
    }
  }

  onCompanyUpdate() {
    if (this.companyInfoForm.valid) {
      this.settings.displayName = this.companyInfoForm.value.displayName;
      // this.storageService
      //   .downloadUrl(this.settings)
      //   .then((res) => {
      //     this.settings.logo = res;
      //   })
      //   .catch((err) => console.error(err));
      this.fs.updateSettings(this.settings);
    }

    console.log(this.settings);
  }

  uploadFile(event) {
    this.storageService
      .uploadFile(event, this.settings)
      .pipe(
        tap((res) => {
          this.currentUpload = res;
        }),
        finalize(() => {
          this.storageService
            .downloadUrl(this.settings)
            .then((res) => {
              this.settings.logo = res;
            })
            .then(() => {
              this.fs.updateSettings(this.settings);
            })
            .catch((err) => console.error(err));
        })
      )
      .subscribe();
  }

  deleteLogo() {
    this.storageService.deleteLogo(this.settings).then(() => {
      this.settings.logo = null;
      this.fs.updateSettings(this.settings);
    });
  }

  ngOnDestroy() {
    this.userDataSubscription.unsubscribe();
  }
}
