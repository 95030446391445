import { BaseModel } from './baseModel';

export class SettingsDO extends BaseModel {
  public domain: string;
  public domainId: string;
  public displayName?: string;
  public logo?: string;
  public scoringThresholds?: ScoringThresholds;
  public adminUserId: string;
  public features?: any;
  public releases?: Releases;
  public tags?: string[];
  public publicRoadmapCols?: string[];
  public financial?: FinancialMetrics;
  public init() {
    this.scoringThresholds = new ScoringThresholds();
    this.releases = new Releases();
    this.financial = new FinancialMetrics();
  }

  protected getPrimitivePropertyKeys(): string[] {
    return [
      'domain',
      'domainId',
      'displayName',
      'logo',
      'scoringThresholds',
      'adminUserId',
      'features',
      'tags',
      'releases',
      'financial',
    ];
  }

  public buildFromObject(object: Object) {
    super.buildFromObject(object);
    if (object['releases'] != null) {
      this.releases = new Releases();
      this.releases.buildFromObject(object['releases']);
    }

    if (object['scoringThresholds'] != null) {
      this.scoringThresholds = new ScoringThresholds();
      this.scoringThresholds.buildFromObject(object['scoringThresholds']);
    }

    if (object['financial'] != null) {
      this.financial = new FinancialMetrics();
      this.financial.buildFromObject(object['financial']);
    }
  }
}

class ScoringThresholds extends BaseModel {
  public lowbp?: number;
  public highbp?: number;

  protected getPrimitivePropertyKeys(): string[] {
    return ['lowbp', 'highbp'];
  }
}

class Releases extends BaseModel {
  public releaseName?: string;
  public releaseDate?: string;
  public releaseCreatedDate?: string;

  protected getPrimitivePropertyKeys(): string[] {
    return ['releaseName', 'releaseDate', 'releaseCreatedDate'];
  }
}

class FinancialMetrics extends BaseModel {
  public wacc?: number;
  public riskFreeRate?: number;

  protected getPrimitivePropertyKeys(): string[] {
    return ['wacc', 'riskFreeRate'];
  }
}
