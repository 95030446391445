import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserDataService } from 'src/services/user-data.service';
import { FeatureDO } from '../../../models/featureDO';
import { Router, ActivatedRoute } from '@angular/router';
import { FirestoreService } from '../../../../services/firestore.service';
import { MatSnackBar, MatDialog, MatDialogConfig } from '@angular/material';
import { EditFeatureComponent } from '../../../common/edit-feature/edit-feature.component';
import { set } from 'lodash';
import { UserDO } from 'src/app/models/userDO';

@Component({
  selector: 'app-feature-detail',
  templateUrl: './feature-detail.component.html',
  styleUrls: ['./feature-detail.component.scss'],
})
export class FeatureDetailComponent implements OnInit, OnDestroy {
  edit = false;
  processing = false;
  userDataSubscription: Subscription;
  featureDataSubscription: Subscription;
  userRoleSubscription: Subscription;
  user: UserDO;
  userRole: string;
  userData: any;
  featuresData: any[];
  featureData: FeatureDO;
  id: string;
  tags: string[] = [];

  editForm = this.fb.group({
    featureDetail: this.fb.group({
      featureName: null,
      featureDescription: null,
      featureTags: null,
    }),
    featureScore: this.fb.group({
      evidenceScore: null,
      impactScore: null,
    }),
  });

  constructor(
    private userDataService: UserDataService,
    private router: Router,
    private route: ActivatedRoute,
    private fs: FirestoreService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  /*
   * Populate initial data needed.
   *
   * */
  ngOnInit() {
    this.processing = true;
    if (this.id) {
      this.featureDataSubscription = this.userDataService.getUserData().subscribe((res) => {
        const allFeatureData = res.features;
        const featureDataVal = allFeatureData.filter(
          (feature: FeatureDO) => feature.id === this.id
        );
        this.featureData = featureDataVal['0'];
        this.tags = this.featureData.featureDetail.featureTags;
        this.user = res.user['0'];
        this.processing = false;
      });

      this.userRoleSubscription = this.userDataService.getRoleData().subscribe((res) => {
        this.userRole = res;
        this.processing = false;
      });
    }
  }

  onSubmit() {
    this.processing = true;
    if (this.editForm.valid) {
      this.edit = false;
    }

    this.processing = false;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EditFeatureComponent, {
      width: '500px',
      data: this.featureData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.snackBar.open('The feature has been updated.', '', { duration: 3000 });
      }
    });
  }

  makePublic(feature: FeatureDO, event) {
    if (feature.publicFeature) {
      feature.publicFeature.flag = event.checked;
      this.fs.updateFeature(feature);
    } else if (!feature.publicFeature) {
      const featureArray = feature;
      const featureReturn = set(featureArray, 'publicFeature.flag', event.checked);
      this.fs.updateFeature(featureReturn);
    }
  }

  ngOnDestroy() {
    this.featureDataSubscription.unsubscribe();
    // this.userDataSubscription.unsubscribe();
  }
}
