import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreService } from './firestore.service';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { SettingsService } from './settings.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SettingsDO } from '../app/models/settingsDO';
import { FeatureDO } from '../app/models/featureDO';
import { UserDO } from 'src/app/models/userDO';
import { flatMap, map } from 'rxjs/operators';
import { FeatureService } from './feature.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private userDataSubject = new BehaviorSubject(<any>null);
  private userRoleSubject = new BehaviorSubject(<any>null);

  user: UserDO;
  userSubscription: Subscription;
  authState: any;

  constructor(
    private afAuth: AngularFireAuth,
    private fs: FirestoreService,
    private userService: UserService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private featureService: FeatureService
  ) {}

  sendUserData(value) {
    this.userDataSubject.next(value);
  }

  getUserData(): Observable<any> {
    return this.userDataSubject.asObservable();
  }

  sendRoleData(value) {
    this.userRoleSubject.next(value);
  }

  getRoleData(): Observable<any> {
    return this.userRoleSubject.asObservable();
  }

  refreshData() {
    this.afAuth.authState.subscribe((authSt) => {
      if (authSt) {
        const userAuth = authSt;
        const uid = userAuth.uid;
        this.fs
          .getUser(uid)
          .pipe(
            flatMap((user: UserDO) => {
              this.user = user['0'];
              this.userService.sendUser(this.user);
              this.sendRoleData(this.user.role);
              return this.fs
                .getSettings(this.user)
                .pipe(map((settings: SettingsDO) => ({ user, settings })));
            }),
            flatMap(({ user, settings }) => {
              this.settingsService.sendSettings(settings);
              return this.fs
                .getFeatures(user['0'])
                .pipe(map((features: FeatureDO) => ({ user, settings, features })));
            }),
            map(({ user, settings, features }) => {
              this.featureService.sendFeatures(features);
              this.sendUserData({ user, settings, features });
              return { user, settings, features };
            })
          )
          .subscribe();
      }
    });
  }
}
