// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firestore: {
    apiKey: 'AIzaSyDh6y3Nn05NPoUUiPLkYy7syZHenTeJNKk',
    authDomain: 'product-portal-dev.firebaseapp.com',
    databaseURL: 'https://product-portal-dev.firebaseio.com',
    projectId: 'product-portal-dev',
    storageBucket: 'product-portal-dev.appspot.com',
    messagingSenderId: '1092771253011',
  },
  API: {
    sendEmailApi: 'https://us-central1-product-portal-dev.cloudfunctions.net/prodmmdEmail',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
