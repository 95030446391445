import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { UserDO } from 'src/app/models/userDO';
import { FirestoreService } from './firestore.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authState = null;
  user: UserDO;
  afUser: any;

  constructor(
    public afAuth: AngularFireAuth,
    private fs: FirestoreService,
    private router: Router
  ) {
    afAuth.authState.subscribe((authSt) => {
      this.authState = authSt;
    });
    this.user = new UserDO();
  }

  get authenticated(): boolean {
    return this.authState !== null;
  }

  get userAccount() {
    return this.authState;
  }

  awaitUserAuth() {
    return this.authState !== null;
  }

  login(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.setPersistence('local').then(() => {
        this.afAuth.auth
          .signInWithEmailAndPassword(email, password)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            reject(errorMessage + errorCode);
          });
      });
    });
  }

  signup(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.setPersistence('local').then(() => {
        this.afAuth.auth
          .createUserWithEmailAndPassword(email, password)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            reject(errorMessage + errorCode);
          });
      });
    });
  }

  passwordReset(email: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth.signInWithPopup(provider).then((res) => {
        resolve(res);
      });
    });
  }

  doGitHubLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new auth.GoogleAuthProvider();
      provider.addScope('user');
      this.afAuth.auth.signInWithPopup(provider).then((res) => {
        resolve(res);
      });
    });
  }

  logout() {
    this.reset();
    return this.afAuth.auth.signOut();
  }

  private reset() {
    localStorage.clear();
  }
}
