import { Component, OnInit, Input } from '@angular/core';
import { FeatureDO } from 'src/app/models/featureDO';
import { CommentDO } from 'src/app/models/commentDO';
import { Observable, Subscription } from 'rxjs';
import { CommentService } from 'src/services/comment.service';
import { MatSnackBar } from '@angular/material';
import { UserDO } from 'src/app/models/userDO';
import { FormBuilder, Validators } from '@angular/forms';
import { UserDataService } from '../../../services/user-data.service';

@Component({
  selector: 'app-feature-comment',
  templateUrl: './feature-comment.component.html',
  styleUrls: ['./feature-comment.component.scss'],
})
export class FeatureCommentComponent implements OnInit {
  commentForm = this.fb.group({
    comment: [null, [Validators.required]],
    restricted: false,
  });

  @Input() feature: FeatureDO;

  user: UserDO;

  userSubscription: Subscription;

  commentsObs: Observable<any>;
  comments: any;

  constructor(
    private commentService: CommentService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private userDataService: UserDataService
  ) {
    this.userSubscription = this.userDataService.getUserData().subscribe((res) => {
      this.user = res.user['0'];
    });
  }

  ngOnInit() {
    console.log(this.user);
    this.commentService.getPublicFeatureComments(this.feature).subscribe((res) => {
      this.comments = res;
    });
  }

  // this.totalVotes = this.votes.pipe(
  //   map((arr) => {
  //     const ratings = arr.map((v) => v.value);
  //     return ratings.length ? ratings.reduce((total, val) => total + val) : 'no votes yet';
  //   })
  // );
  generateUrl(comment) {
    const link = 'https://api.adorable.io/avatars/285/' + comment.userId;
    return link;
  }

  sendComment() {
    const comment = this.commentForm.value.comment;
    const restricted = this.commentForm.value.restricted;
    this.commentService.makeComment(this.feature, this.user, comment, restricted).then(() => {
      this.commentForm.reset();
      this.snackBar.open('Comment sent', '', { duration: 2000 });
    });
  }
}
