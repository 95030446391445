export abstract class BaseModel {
  protected abstract getPrimitivePropertyKeys(): string[];

  public buildFromObject(object: Object) {
    if (!object) {
      return;
    }
    const primitiveProperties: string[] = this.getPrimitivePropertyKeys();
    primitiveProperties.forEach((property: string) => {
      if (object.hasOwnProperty(property)) {
        this[property] = object[property];
      }
    });
  }
}
