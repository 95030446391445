import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import * as uuidv4 from 'uuid/v4';
import { delay } from 'lodash';
import { DateTime } from 'luxon';

import { ScoringService } from 'src/services/scoring.service';
import { FirestoreService } from '../../../services/firestore.service';
import { UserDataService } from '../../../services/user-data.service';
import { SettingsService } from '../../../services/settings.service';

import { FeatureDO } from '../../models/featureDO';
import { UserDO } from '../../models/userDO';
import { SettingsDO } from '../../models/settingsDO';

@Component({
  selector: 'app-scoring-form',
  templateUrl: './scoring-form.component.html',
  styleUrls: ['./scoring-form.component.scss'],
})
export class ScoringFormComponent implements OnInit, OnDestroy {
  /* Form Setup */
  processing = false;

  scoringForm = this.fb.group({
    featureDetail: this.fb.group({
      featureName: [null, [Validators.required]],
      featureDescription: [null, [Validators.required]],
      featureTags: null,
      featureRevenue: null,
      featureCost: null,
    }),
    featureScore: this.fb.group({
      evidenceScore: ['1', [Validators.required]],
      impactScore: ['1', [Validators.required]],
    }),
    publicFeature: this.fb.group({
      flag: null,
    }),
  });

  /* DO Subscriptions */

  feature: FeatureDO;
  featureSubscription: Subscription;

  user: UserDO;
  userSubscription: Subscription;

  // userSettingsSubscription: Subscription;
  settings: SettingsDO;

  userData: any;
  tags: any;

  /* End DO Subscriptions */

  constructor(
    private fb: FormBuilder,
    private scoringService: ScoringService,
    private settingsService: SettingsService,
    private router: Router,
    private fs: FirestoreService,
    public snackBar: MatSnackBar,
    private userDataService: UserDataService
  ) {
    this.feature = new FeatureDO();
    this.feature.init();
    this.user = new UserDO();

    this.userSubscription = this.userDataService.getUserData().subscribe((res) => {
      this.userData = res;
      this.settings = this.userData.settings['0'];
      this.tags = this.settings.tags;
      this.user = this.userData.user['0'];
    });
  }

  onSubmit() {
    this.processing = true;
    this.feature = this.scoringForm.value;
    if (this.feature.featureScore.evidenceScore === null) {
      this.feature.featureScore.evidenceScore = 1;
    } else if (this.feature.featureScore.impactScore === null) {
      this.feature.featureScore.impactScore = 1;
    }

    if (this.scoringForm.valid) {
      this.feature.submitDate = DateTime.local().toString();
      this.feature.id = uuidv4();
      this.feature.domainId = this.userData.user['0'].domainId;
      this.feature.userId = this.userData.user['0'].userId;
      this.feature.publicFeature.flag = false;
      this.feature.publicFeature.stage = this.settings.publicRoadmapCols['0'];
      this.scoringService
        .computeScore(this.feature)
        .then((compFeature: FeatureDO) => {
          this.scoringService.scoreCategory(compFeature, this.settings).then((res) => {
            this.fs.addFeature(this.feature);
            this.userDataService.refreshData();
            delay(() => this.router.navigate(['app/score-result']), 1000);
            this.processing = false;
          });
        })

        // this.router.navigate(['app/score-result']);
        .catch((err) => {
          this.processing = false;
          this.snackBar.open(err, 'close', {
            duration: 10000,
          });
        });
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
