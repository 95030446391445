import { BaseModel } from './baseModel';

export class UserDO extends BaseModel {
  public domainId: string;
  public userId: string;
  public userName?: string;
  public firstName?: string;
  public lastName?: string;
  public email: string;
  public role: string;
  public disabled?: boolean;
  public tempPass?: string;

  protected getPrimitivePropertyKeys(): string[] {
    return [
      'domainId',
      'userId',
      'userName',
      'firstName',
      'lastName',
      'emall',
      'role',
      'disabled',
      'tempPass',
    ];
  }
}
