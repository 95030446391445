import { BaseModel } from './baseModel';

export class FeatureDO extends BaseModel {
  public featureDetail: FeatureDetail;
  public submitDate: string;
  public id: string;
  public featureScore: FeatureScore;
  public domainId: string;
  public userId: string;
  public publicFeature?: FeaturePublic;
  public init() {
    this.featureScore = new FeatureScore();
    this.featureDetail = new FeatureDetail();
    this.publicFeature = new FeaturePublic();
  }

  protected getPrimitivePropertyKeys(): string[] {
    return [
      'featureName',
      'featureDescription',
      'submitDate',
      'id',
      'featureScore',
      'domainId',
      'userId',
      'publicFeature',
    ];
  }

  public buildFromObject(object: Object) {
    super.buildFromObject(object);
    if (object['featureScore'] != null) {
      this.featureScore = new FeatureScore();
      this.featureScore.buildFromObject(object['featureScore']);
    }

    if (object['featureDetail'] != null) {
      this.featureDetail = new FeatureDetail();
      this.featureDetail.buildFromObject(object['featureDetail']);
    }

    if (object['publicFeature'] != null) {
      this.publicFeature = new FeaturePublic();
      this.publicFeature.buildFromObject(object['publicFeature']);
    }
  }
}

class FeatureScore extends BaseModel {
  public evidenceScore: number;
  public impactScore: number;
  public calculatedScore?: number;
  public scoreCategory?: string;

  protected getPrimitivePropertyKeys(): string[] {
    return ['evidenceScore', 'impactScore', 'calculatedScore', 'scoreCategory'];
  }
}

class FeatureDetail extends BaseModel {
  public featureName: string;
  public featureDescription: string;
  public featureTags?: string[];
  public featureCost?: number;
  public featureRevenue?: number;

  protected getPrimitivePropertyKeys(): string[] {
    return ['featureName', 'featureDescription', 'tags', 'featureCost', 'featureRevenue'];
  }
}

class FeaturePublic extends BaseModel {
  public flag?: boolean;
  public stage?: string;

  protected getPrimitivePropertyKeys(): string[] {
    return ['flag', 'stage'];
  }
}
