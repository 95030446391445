import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FeatureDO } from 'src/app/models/featureDO';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private featuresSubject = new BehaviorSubject(<FeatureDO>null);

  constructor(private fs: FirestoreService) {}

  sendFeatures(value: FeatureDO) {
    this.featuresSubject.next(value);
  }

  getFeatures(): Observable<any> {
    return this.featuresSubject.asObservable();
  }

  makePublic(feature: FeatureDO) {
    const featurePub = feature;
    featurePub.publicFeature.flag = true;
    return this.fs.updateFeature(featurePub);
  }

  makePrivate(feature: FeatureDO) {
    const featurePub = feature;
    featurePub.publicFeature.flag = false;
    return this.fs.updateFeature(featurePub);
  }

  /**
   * Calculates the Net Present Value of a given initial investment
   * cost and an array of cash flow values with the specified discount rate.
   *
   * @param rate - The discount rate percentage
   * @param initialCost - The initial investment
   * @param cashFlows - An array of future payment amounts
   * @return The calculated Net Present Value
   */

  public getNPV(rate: number, initialCost: number, cashFlows: []) {
    return cashFlows.reduce(
      (accumulator, currentValue, index) =>
        accumulator + currentValue / Math.pow(rate / 100 + 1, index + 1),
      initialCost
    );
  }
}
