import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { UserDataService } from '../../../../services/user-data.service';
import { Subscription } from 'rxjs';
import { FeatureDO } from '../../../models/featureDO';
import { FirestoreService } from '../../../../services/firestore.service';
import { set } from 'lodash';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-feature-table',
  templateUrl: './feature-table.component.html',
  styleUrls: ['./feature-table.component.scss'],
})
export class FeatureTableComponent implements OnInit, OnDestroy, AfterViewInit {
  processing = true;
  userDataSubscription: Subscription;
  userData: any;
  userRoleAdmin;

  expandedElement: FeatureDO | null;
  displayedColumns: string[] = [
    'id',
    'name',
    'description',
    'stage',
    'evidence',
    'impact',
    'score',
    'public',
  ];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userDataService: UserDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private fs: FirestoreService,
    private userService: UserService
  ) {
    this.userDataSubscription = this.userDataService.getUserData().subscribe((user) => {
      this.userData = user;
      // const flatArray = [].concat(...this.userData.features);
      // console.log(flatArray);
      console.log(this.userData.features);
      if (this.userData) {
        this.dataSource = new MatTableDataSource<FeatureDO>(this.userData.features);
        console.log(this.dataSource);
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
        // this.dataSource.sortingDataAccessor = this.pathDataAccessor;
        this.dataSource.sort = this.sort;
        this.userRoleAdmin = this.userData.user['0'].role;
      }
      this.processing = false;
    });
  }

  // pathDataAccessor(item: any, path: string): any {
  //   return path.split('.').reduce((accumulator: any, key: string) => {
  //     return accumulator ? accumulator[key] : undefined;
  //   }, item);
  // }

  // getProperty = (obj, path) => path.split('.').reduce((o, p) => o && o[p], obj);

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource);

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  navDetail(id: string) {
    this.router.navigate(['app/detail', id]);
  }

  makePublic(feature: FeatureDO, event) {
    if (feature.publicFeature) {
      feature.publicFeature.flag = event.checked;
      this.fs.updateFeature(feature);
    } else if (!feature.publicFeature) {
      const featureArray = feature;
      const featureReturn = set(featureArray, 'publicFeature.flag', event.checked);
      this.fs.updateFeature(featureReturn);
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}
}
