import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FirestoreService } from 'src/services/firestore.service';
import { UserService } from 'src/services/user.service';
import { SettingsService } from 'src/services/settings.service';
import { AuthService } from 'src/services/auth.service';
import { Subscription } from 'rxjs';
import { SettingsDO } from 'src/app/models/settingsDO';
import { findIndex } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { duration } from 'moment';

@Component({
  selector: 'app-roadmap-labels',
  templateUrl: './roadmap-labels.component.html',
  styleUrls: ['./roadmap-labels.component.scss'],
})
export class RoadmapLabelsComponent implements OnInit {
  publicRoadmapLabelsForm = this.fb.group({
    col1: null,
    col2: null,
    col3: null,
    col4: null,
  });

  userSettingsSubscription: Subscription;
  settings: SettingsDO;

  constructor(
    private fb: FormBuilder,
    private fs: FirestoreService,
    private userService: UserService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {
    this.settings = new SettingsDO();
    this.userSettingsSubscription = this.settingsService.getSettings().subscribe((res) => {
      this.settings = res['0'];
    });
  }

  onLabelSubmit() {
    if (this.publicRoadmapLabelsForm.valid) {
      const publicRoadmapColsNew = this.publicRoadmapLabelsForm.value;
      const roadmapValues = this.settings.publicRoadmapCols;
      // console.log(publicRoadmapColsNew);
      // console.log(roadmapValues);
      // console.log(this.publicRoadmapLabelsForm.value);
      if (this.publicRoadmapLabelsForm.get('col1') !== null) {
        this.settings.publicRoadmapCols.splice(0, 1, publicRoadmapColsNew.col1);
      }
      if (this.publicRoadmapLabelsForm.get('col2') !== null) {
        this.settings.publicRoadmapCols.splice(1, 1, publicRoadmapColsNew.col2);
      }
      if (this.publicRoadmapLabelsForm.get('col3') !== null) {
        this.settings.publicRoadmapCols.splice(2, 1, publicRoadmapColsNew.col3);
      }
      if (this.publicRoadmapLabelsForm.get('col4') !== null) {
        this.settings.publicRoadmapCols.splice(3, 1, publicRoadmapColsNew.col4);
      }
      // this.settings.publicRoadmapCols.splice(4, 5, 'Archived');
      // console.log(this.settings.publicRoadmapCols);
      // console.log(this.settings);
      this.fs.updateSettings(this.settings).then(() => {
        this.snackBar.open('Your labels have been updated', '', { duration: 3000 });
      });
    }
  }

  ngOnInit() {
    this.publicRoadmapLabelsForm.setValue({
      col1: this.settings.publicRoadmapCols['0'],
      col2: this.settings.publicRoadmapCols['1'],
      col3: this.settings.publicRoadmapCols['2'],
      col4: this.settings.publicRoadmapCols['3'],
    });
  }
}

