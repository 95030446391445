import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { UserDataService } from 'src/services/user-data.service';
import { FirestoreService } from 'src/services/firestore.service';
import { ReleaseDO } from 'src/app/models/releaseDO';

@Component({
  selector: 'app-release-table',
  templateUrl: './release-table.component.html',
  styleUrls: ['./release-table.component.scss'],
})
export class ReleaseTableComponent implements OnInit {
  userDataSubscription: Subscription;
  domainReleases: Subscription;
  userData: any;

  @Input() releases: ReleaseDO[];

  displayedColumns: string[] = ['releaseName', 'releaseDate', 'releaseDateCreated'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort;

  constructor(private userDataService: UserDataService, private fs: FirestoreService) {
    // this.userDataSubscription = this.userDataService.getUserData().subscribe((user) => {
    //   this.userData = user;
    //   this.domainReleases = this.fs
    //     .getReleaseFromDomain(this.userData.settings.domainId)
    //     .subscribe((res: ReleaseDO[]) => {
    //       this.releases = res;
    //     });
    // });
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }
}
