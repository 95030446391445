import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { UserDataService } from '../../../../../services/user-data.service';
import { FeatureDO } from 'src/app/models/featureDO';
import { FirestoreService } from '../../../../../services/firestore.service';
import { set } from 'lodash';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EditFeatureComponent } from 'src/app/common/edit-feature/edit-feature.component';
import { UserDO } from 'src/app/models/userDO';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feature-kanban-stages',
  templateUrl: './feature-kanban-stages.component.html',
  styleUrls: ['./feature-kanban-stages.component.scss'],
})
export class FeatureKanbanStagesComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  userDataSubscription: Subscription;
  userDataSubscriptionGroups: Subscription;
  user: UserDO;
  features: FeatureDO[] = [];
  roadmapCols: [string, string, string, string, string];

  combinedData: any;
  showArchived = false;

  constructor(
    private userDataService: UserDataService,
    private fs: FirestoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router
  ) {
    this.userDataSubscription = this.userDataService.getUserData().subscribe((user) => {
      this.features = user.features;
      this.roadmapCols = user.settings['0'].publicRoadmapCols;
      this.user = user.user['0'];
      console.log(this.roadmapCols);
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.userDataSubscription.unsubscribe();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    // console.log(event);
  }

  navDetail(id: string) {
    this.router.navigate(['app/detail', id]);
  }

  makePublic(feature: FeatureDO, event) {
    if (feature.publicFeature) {
      feature.publicFeature.flag = event.checked;
      this.fs.updateFeature(feature);
    } else if (!feature.publicFeature) {
      const featureArray = feature;
      const featureReturn = set(featureArray, 'publicFeature.flag', event.checked);
      this.fs.updateFeature(featureReturn);
    }
  }

  openDialog(feature: FeatureDO): void {
    const dialogRef = this.dialog.open(EditFeatureComponent, {
      width: '500px',
      data: feature,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.snackBar.open('The feature has been updated.', '', { duration: 3000 });
      }
    });
  }
}
