import { Component, OnInit, Input } from '@angular/core';
import { UserDO } from 'src/app/models/userDO';
import { FormBuilder } from '@angular/forms';
import { FirestoreService } from '../../../../services/firestore.service';
import { StorageService } from '../../../../services/storage.service';
import { SettingsDO } from 'src/app/models/settingsDO';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  @Input() user: UserDO;
  @Input() userSettings: SettingsDO;

  userInfoForm = this.fb.group({
    firstName: '',
    lastName: '',
    userName: '',
  });

  constructor(
    private fb: FormBuilder,
    private fs: FirestoreService,
    private storageService: StorageService
  ) {}

  onUserUpdate() {
    if (this.userInfoForm.valid) {
      this.user.userName = this.userInfoForm.value.userName;
      this.user.firstName = this.userInfoForm.value.firstName
        ? this.userInfoForm.value.firstName
        : '';
      this.user.lastName = this.userInfoForm.value.lastName ? this.userInfoForm.value.lastName : '';
    }
    this.fs.updateUser(this.user);
  }

  ngOnInit() {}
}
