import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserDO } from '../app/models/UserDO';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject = new BehaviorSubject(<UserDO>null);

  constructor() {}

  sendUser(value) {
    this.userSubject.next(value);
  }

  getUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  // A service to return whether the user is an admin level user or not. This is used to gate and guard key functionality.
  get isAdmin() {
    return this.userSubject.subscribe((u) => {
      if (u.role === 'admin') {
        return true;
      } else {
        return false;
      }
    });
  }
}
