import { Component, OnInit, Input } from '@angular/core';
import { FeatureDO } from 'src/app/models/featureDO';
import { SettingsDO } from 'src/app/models/settingsDO';
import { Observable, Subscription } from 'rxjs';
import { UserDataService } from 'src/services/user-data.service';

@Component({
  selector: 'app-status-tiles',
  templateUrl: './status-tiles.component.html',
  styleUrls: ['./status-tiles.component.scss'],
})
export class StatusTilesComponent implements OnInit {
  processing = false;
  @Input() featuresData: FeatureDO[];
  @Input() userSettings: SettingsDO;

  featuresDataLength;

  votes: Observable<any>;
  avgFeatureScore: any;
  nFeatures: any;
  highFeatures: any;

  userSub: Subscription;

  constructor(private userDataService: UserDataService) {
    this.userSub = this.userDataService.getUserData().subscribe((data) => {
      this.featuresData = data.features;
      this.userSettings = data.settings;

      // Creating total feature count
      if (this.featuresData) {
        this.nFeatures = this.featuresData.length;

        // Calling AVG tile feature calc
        this.avgFeatureScore = this.getAverageScore();

        // Get high feature count only
        this.highFeatures = this.getHighPriorityCount();
        // this.setChart1(data.features, data.settings);
      }
    });
  }

  ngOnInit() {}

  getAverageScore() {
    const scores = this.featuresData.map((v) => v.featureScore.calculatedScore);
    return scores.length
      ? scores.reduce((total, val) => total + val) / scores.length
      : 'Cannot Calculate an Average';
  }

  getHighPriorityCount() {
    const highPriority = this.featuresData
      .map((v) => v.featureScore.scoreCategory)
      .filter((v) => v === 'high');

    return highPriority.length ? highPriority.length : 'No features in HIGH priority';
  }
}
