import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FeatureDO } from '../app/models/featureDO';
import { SettingsDO } from '../app/models/settingsDO';

@Injectable({
  providedIn: 'root',
})
export class ScoringService {
  constructor() {}

  private scoreSubject$ = new BehaviorSubject(<FeatureDO>{});

  sendScore(value) {
    this.scoreSubject$.next(value);
  }

  // clearScore() {
  //   this.scoreSubject$.next({});
  // }

  getScore(): Observable<FeatureDO> {
    return this.scoreSubject$.asObservable();
  }

  /*
   * End observables
   */

  public computeScore(feature: FeatureDO) {
    return new Promise((resolve, reject) => {
      feature.featureScore.calculatedScore =
        feature.featureScore.evidenceScore * feature.featureScore.impactScore;
      if (feature.featureScore.calculatedScore >= 0) {
        this.sendScore(feature);
        resolve(feature);
      } else {
        reject(
          'Not Valid - Cannot calculate a score. There may be an issue with the scoring inputs.'
        );
      }
    });
  }

  public reCalcScore(feature: FeatureDO) {
    const calcScore = feature.featureScore.evidenceScore * feature.featureScore.impactScore;
    return calcScore;
  }

  public reCalcScoreCategory(feature: FeatureDO, settings: SettingsDO) {
    if (feature.featureScore.calculatedScore <= settings.scoringThresholds.lowbp) {
      const category = 'low';
      return category;
    } else if (
      feature.featureScore.calculatedScore < settings.scoringThresholds.highbp &&
      feature.featureScore.calculatedScore > settings.scoringThresholds.lowbp
    ) {
      const category = 'medium';
      return category;
    } else if (feature.featureScore.calculatedScore >= settings.scoringThresholds.highbp) {
      const category = 'high';
      return category;
    }
  }

  public scoreCategory(feature: FeatureDO, settings: SettingsDO) {
    return new Promise((resolve, reject) => {
      if (feature.featureScore.calculatedScore <= settings.scoringThresholds.lowbp) {
        feature.featureScore.scoreCategory = 'low';
        this.sendScore(feature);
        resolve(feature);
      } else if (
        feature.featureScore.calculatedScore < settings.scoringThresholds.highbp &&
        feature.featureScore.calculatedScore > settings.scoringThresholds.lowbp
      ) {
        feature.featureScore.scoreCategory = 'medium';
        this.sendScore(feature);
        resolve(feature);
      } else if (feature.featureScore.calculatedScore >= settings.scoringThresholds.highbp) {
        feature.featureScore.scoreCategory = 'high';
        this.sendScore(feature);
        resolve(feature);
      } else {
        reject('Cannot generate a score category - please check');
      }
    });
  }
}
