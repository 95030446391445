import { Component, OnInit, OnDestroy } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { FirestoreService } from '../../../services/firestore.service';
import { UserService } from '../../../services/user.service';
import { Subscription, Observable } from 'rxjs';
import { UserDO } from '../../models/userDO';
import { SettingsService } from 'src/services/settings.service';
import { SettingsDO } from 'src/app/models/settingsDO';

import { switchMap } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  scoringThresholdsForm = this.fb.group({
    lowbp: 8,
    highbp: 18,
  });

  scoringThresholds: {
    lowbp;
    highbp;
  };

  userSettingsSubscription: Subscription;
  userSubscriptions: Subscription;
  userObservable$: Observable<UserDO[]>;
  userSettings$: Observable<SettingsDO[]>;
  user: UserDO;
  userSettings: SettingsDO;

  userObservableSource$: Observable<any>;

  constructor(
    private fb: FormBuilder,
    private fs: FirestoreService,
    private userService: UserService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {
    this.user = new UserDO();
    this.snackBar.open(
      'Building releases is in development, more functionality will be unlocked shortly!',
      '',
      { duration: 5000 }
    );
  }

  ngOnInit() {
    if (this.authService.authenticated) {
      this.userSubscriptions = this.userService
        .getUser()
        .pipe(
          switchMap((res) => {
            this.user = res;
            return this.fs.getSettings(this.user);
          })
        )
        .subscribe((res) => {
          this.userSettings = res['0'];
          this.scoringThresholds = res['0'].scoringThresholds;
          this.scoringThresholdsForm.setValue({
            lowbp: this.scoringThresholds.lowbp,
            highbp: this.scoringThresholds.highbp,
          });
        });
    }
  }

  getRole() {
    return this.user.role;
  }

  onSettingsSubmit() {
    this.userSettings.scoringThresholds.lowbp = this.scoringThresholdsForm.value.lowbp;
    this.userSettings.scoringThresholds.highbp = this.scoringThresholdsForm.value.highbp;
    this.fs.updateSettings(this.userSettings).then(() => {
      this.snackBar.open('Your score thresholds have been updated.', '', { duration: 3000 });
    });
  }

  ngOnDestroy() {
    if (this.authService.authenticated) {
      this.userSubscriptions.unsubscribe();
    }
  }
}
