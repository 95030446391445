import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserDataService } from './user-data.service';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class DisabledResolver implements Resolve<any> {
  userDisabled = false;
  constructor(
    private authService: AuthService,
    private userDataService: UserDataService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const disabledRole = true;
    this.userDataService.getUserData().subscribe((user) => {
      if (user != null || undefined) {
        this.userDisabled = user.user['0'].disabled ? user.user['0'].disabled : false;
        if (this.userDisabled !== disabledRole) {
          return true;
        } else if (this.userDisabled === disabledRole) {
          this.snackBar.open(
            'Your account has been disabled, please contact your company product team or software administrator',
            'Ok'
          );
          this.router.navigate(['login']);
          return false;
        }
      }
    });
  }
}
