import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserDataService } from 'src/services/user-data.service';

@Component({
  selector: 'app-feature-list',
  templateUrl: './feature-list.component.html',
  styleUrls: ['./feature-list.component.scss'],
})
export class FeatureListComponent implements OnInit, OnDestroy {
  formatType = 'table';
  userDataSubscription: Subscription;
  userData: any;

  constructor(private userDataService: UserDataService) {}

  ngOnInit() {
    this.userDataSubscription = this.userDataService.getUserData().subscribe((user) => {
      this.userData = user;
    });
  }

  ngOnDestroy() {
    this.userDataSubscription.unsubscribe();
  }
}
