import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

import { FeatureDO } from '../../models/featureDO';
import { UserDO } from '../../models/userDO';
import { SettingsDO } from '../../models/settingsDO';

import { ChartDataService } from '../../../services/chart-data.service';
import { UserDataService } from '../../../services/user-data.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit, AfterViewInit {
  processing = false;
  authState: any;

  // chart1: any = [];
  // chart2: any = [];
  // chart3: any = [];

  featuresSub: any = [];
  userSub: Subscription;
  user: any;
  userSettingsSub: Subscription;
  userSettings: SettingsDO;
  featuresData: FeatureDO[];

  constructor(
    private router: Router,
    private userDataService: UserDataService,
    private afAuth: AngularFireAuth,
    private chartDataService: ChartDataService
  ) {
    this.user = new UserDO();
  }

  ngOnInit() {
    // this.userSub = this.userDataService.getUserData().subscribe((data) => {
    //   this.featuresData = data.features;
    //   this.userSettings = data.settings;
    //   // this.setChart2(data.features, data.settings);
    //   // this.setChart3(data.features, data.settings);
    //   console.log(this.featuresData);
    // });
  }

  ngAfterViewInit() {}

  // setChart2(features: FeatureDO[], settings?: SettingsDO, timeFrame?: number) {
  //   const chart1Data = this.chartDataService.generatePriorityCountData(
  //     features,
  //     settings,
  //     timeFrame
  //   );
  //   // const chart1Time = this.chartDataService.generateDates(features);
  //   // const chart1Labels = this.chartDataService.generateLabels(features);
  //   const data = {
  //     labels: ['Low', 'Medium', 'High'],
  //     datasets: [
  //       {
  //         data: chart1Data,
  //         backgroundColor: ['#ff8787', '#f6d47d', '#60d8ca'],
  //         fill: true,
  //       },
  //     ],
  //   };
  //   const options = {
  //     title: {
  //       display: true,
  //       text: 'Feature Priority Breakdown',
  //     },
  //   };
  //   this.chart2 = new Chart('chart2', {
  //     type: 'doughnut',
  //     data: data,
  //     options: options,
  //   });
  // }

  // setChart3(features: FeatureDO[], settings?: SettingsDO, timeFrame?: number) {
  //   const labelSeries = this.chartDataService.previousNMos(3);
  //   const chart3Data = this.chartDataService.generatePriorityCountMonthlyData(
  //     features,
  //     settings,
  //     timeFrame
  //   );
  //   // const chart1Time = this.chartDataService.generateDates(features);
  //   const data = {
  //     labels: labelSeries,
  //     datasets: [
  //       {
  //         label: 'Low',
  //         data: [10, 25, 15],
  //         backgroundColor: '#ff8787',
  //         fill: true,
  //       },
  //       {
  //         label: 'Medium',
  //         data: [10, 25, 15],
  //         backgroundColor: '#f6d47d',
  //         fill: true,
  //       },
  //       {
  //         label: 'High',
  //         data: [10, 25, 15],
  //         backgroundColor: '#60d8ca',
  //         fill: true,
  //       },
  //     ],
  //   };
  //   const options = {
  //     title: {
  //       display: true,
  //       text: 'Total Submissions by Type',
  //     },
  //     responsive: true,
  //     scales: {
  //       xAxes: [
  //         {
  //           stacked: true,
  //         },
  //       ],
  //       yAxes: [
  //         {
  //           stacked: true,
  //         },
  //       ],
  //     },
  //   };
  //   this.chart3 = new Chart('chart3', {
  //     type: 'bar',
  //     data: data,
  //     options: options,
  //   });
  // }
}
