import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { UserDataService } from '../../../../../services/user-data.service';
import { FeatureDO } from 'src/app/models/featureDO';
import { FirestoreService } from '../../../../../services/firestore.service';
import { set } from 'lodash';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EditFeatureComponent } from 'src/app/common/edit-feature/edit-feature.component';
import { UserDO } from 'src/app/models/userDO';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feature-kanban-priority',
  templateUrl: './feature-kanban-priority.component.html',
  styleUrls: ['./feature-kanban-priority.component.scss'],
})
export class FeatureKanbanPriorityComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  userDataSubscription: Subscription;
  userDataSubscriptionGroups: Subscription;
  user: UserDO;

  combinedData: any;
  lowArray = [];
  mediumArray = [];
  highArray = [];

  constructor(
    private userDataService: UserDataService,
    private fs: FirestoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}

  ngOnInit() {
    this.userDataSubscription = this.userDataService.getUserData().subscribe((user) => {
      const featuresArray = user.features;
      const userSettings = user.settings['0'];
      this.user = user.user['0'];
      this.lowArray = featuresArray.filter(
        (feature: FeatureDO) =>
          feature.featureScore.calculatedScore <= userSettings.scoringThresholds.lowbp
      );
      this.mediumArray = featuresArray.filter(
        (feature: FeatureDO) =>
          feature.featureScore.calculatedScore > userSettings.scoringThresholds.lowbp &&
          feature.featureScore.calculatedScore < userSettings.scoringThresholds.highbp
      );
      this.highArray = featuresArray.filter(
        (feature: FeatureDO) =>
          feature.featureScore.calculatedScore >= userSettings.scoringThresholds.highbp
      );
    });
  }

  ngOnDestroy() {
    this.userDataSubscription.unsubscribe();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    // console.log(event);
  }

  navDetail(id: string) {
    this.router.navigate(['app/detail', id]);
  }

  makePublic(feature: FeatureDO, event) {
    if (feature.publicFeature) {
      feature.publicFeature.flag = event.checked;
      this.fs.updateFeature(feature);
    } else if (!feature.publicFeature) {
      const featureArray = feature;
      const featureReturn = set(featureArray, 'publicFeature.flag', event.checked);
      this.fs.updateFeature(featureReturn);
    }
  }

  openDialog(feature: FeatureDO): void {
    const dialogRef = this.dialog.open(EditFeatureComponent, {
      width: '500px',
      data: feature,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.snackBar.open('The feature has been updated.', '', { duration: 3000 });
      }
    });
  }
}
