import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { UserDO } from 'src/app/models/userDO';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from 'src/services/firestore.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit {
  loginForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]],
  });

  singupClientForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]],
    userName: [null, [Validators.required]],
  });

  processing = false;
  newClientUser: UserDO;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private fs: FirestoreService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.newClientUser = new UserDO();
  }

  ngOnInit() {}

  login() {
    if (this.loginForm.valid) {
      this.processing = true;
      const email = this.loginForm.value.email;
      const password = this.loginForm.value.password;
      this.authService.afAuth.auth.setPersistence('session');
      this.authService
        .login(email, password)
        .then(() => {
          this.processing = false;
          this.dialogRef.close('success');
        })
        .catch((err) => {
          this.processing = false;
          this.snackBar.open(err, 'close', {
            duration: 10000,
          });
          this.dialogRef.close('error');
        });
    }
  }

  onClientSignup() {
    if (this.singupClientForm.valid) {
      this.processing = true;
      const password = this.singupClientForm.value.password;
      this.newClientUser.email = this.singupClientForm.value.email;
      this.newClientUser.domainId = this.data.domainId;
      this.newClientUser.role = 'client';
      this.newClientUser.userName = this.singupClientForm.value.userName;
      this.authService
        .signup(this.newClientUser.email, password)
        .then((res) => {
          this.newClientUser.userId = res.user.uid;
          this.fs.addUser(this.newClientUser);
          this.processing = false;
        })
        .catch((err) => {
          this.processing = false;
          this.snackBar.open(err, '', { duration: 3000 });
        });
    }
  }

  contGuest() {
    this.dialogRef.close('anon');
  }
}
