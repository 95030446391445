import { Injectable } from '@angular/core';
import { CommentDO } from '../app/models/commentDO';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { UserDO } from 'src/app/models/userDO';
import { FeatureDO } from 'src/app/models/featureDO';
import { DateTime } from 'luxon';
import * as uuidv4 from 'uuid/v4';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private commentCollection: AngularFirestoreCollection<CommentDO>;
  constructor(private afs: AngularFirestore) {
    this.commentCollection = afs.collection<CommentDO>('comment');
  }

  getUserComments(user: UserDO) {
    const commentRef = this.afs.collection('comment', (ref) =>
      ref.where('userId', '==', user.userId)
    );
    return commentRef.valueChanges();
  }

  getPublicFeatureComments(feature: FeatureDO) {
    const commentRef = this.afs.collection('comment', (ref) =>
      ref.where('featureId', '==', feature.id)
    );
    return commentRef.valueChanges();
  }

  makeComment(feature: FeatureDO, user: UserDO, comment: string, restricted: boolean) {
    const commentToSend = {
      userId: user.userId,
      userName: user.userName ? user.userName : user.email,
      commentId: uuidv4(),
      comment: comment,
      restricted: restricted,
      commentDate: DateTime.local().toISO(),
      featureId: feature.id,
    };
    const commentDoc = `${commentToSend.userId}_${commentToSend.featureId}_${
      commentToSend.commentId
    }`;
    return this.commentCollection
      .doc(commentDoc)
      .set(commentToSend)
      .catch((err) => {
        console.log(err);
      });
  }

  updateComment(feature: FeatureDO, comment: CommentDO, value: string) {
    const commentDoc = `${comment.userId}_${comment.commentId}`;
    return this.commentCollection
      .doc(feature.id)
      .collection(comment.commentId)
      .doc(commentDoc)
      .update(comment)
      .catch((err) => {
        console.log(err);
      });
  }
}
