import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, of } from 'rxjs';
import { SettingsDO } from 'src/app/models/settingsDO';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private basePath = '/uploads';
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  constructor(private storage: AngularFireStorage) {}

  uploadFile(event, settings: SettingsDO) {
    const file = event.target.files[0];

    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }

    const filePath = `logos/${settings.domainId}`;
    const customMetadata = { domain: settings.domain };
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    // observe percentage changes
    const percentChange = (this.uploadPercent = task.percentageChanges());
    task
      .snapshotChanges()
      .pipe(finalize(() => (this.downloadURL = fileRef.getDownloadURL())))
      .subscribe();

    return this.uploadPercent;
  }

  downloadUrl(settings: SettingsDO) {
    const filePath = `logos/${settings.domainId}`;
    // const fileRef = this.storage.ref(filePath);
    return this.storage
      .ref(filePath)
      .getDownloadURL()
      .toPromise();
  }

  deleteLogo(settings: SettingsDO) {
    console.log(settings);
    return this.storage.storage
      .refFromURL(settings.logo)
      .delete()
      .catch((err) => {
        console.log(err);
      });
  }
}
