import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { VotingService } from 'src/services/voting.service';
import { map } from 'rxjs/operators';
import { FeatureDO } from 'src/app/models/featureDO';
import { MatSnackBar } from '@angular/material';
import { UserDO } from 'src/app/models/userDO';

@Component({
  selector: 'app-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss'],
})
export class VoteComponent implements OnInit {
  @Input() user;
  @Input() feature: FeatureDO;

  votes: Observable<any>;
  totalVotes: Observable<any>;

  constructor(private votingService: VotingService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.votes = this.votingService.getFeatureVotes(this.feature);

    this.totalVotes = this.votes.pipe(
      map((arr) => {
        const ratings = arr.map((v) => v.value);
        return ratings.length ? ratings.reduce((total, val) => total + val) : 'no votes yet';
      })
    );
  }

  voteHandler(value) {
    this.votingService.setVote(this.feature, value, this.user).then(() => {
      this.snackBar.open('Your vote was logged', '', { duration: 3000 });
    });
  }
}
