import { OnInit, Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription, Observable } from 'rxjs';
import { FirestoreService } from '../services/firestore.service';
import { UserDO } from './models/userDO';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  processing = false;
  getUserSubscription: Subscription;
  authServiceUserSubscription: Subscription;

  user: UserDO;

  authState = null;
  routeValue;

  userObs: Observable<UserDO>;

  constructor(
    private fs: FirestoreService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private activeatedRoute: ActivatedRoute
  ) {
    this.processing = true;
    this.activeatedRoute.params.pipe(
      map((params) => {
        this.routeValue = params['domain'];
      })
    );
    this.afAuth.authState.subscribe((authSt) => {
      if (authSt) {
        this.authState = authSt;
        const user = this.authState;
        const uid = user.uid;
        this.userObs = this.fs.getUser(uid);
        console.log('auth state returned true');
        this.processing = false;
        return true;
        // return this.router.navigate(['/app']);
      } else if (!this.routeValue) {
        console.log('auth state returned false');
        // this.router.navigate(['/login']);
        this.processing = false;
        return false;
      }
    });
    this.user = new UserDO();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.getUserSubscription.unsubscribe();
    this.authServiceUserSubscription.unsubscribe();
  }
}
