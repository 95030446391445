import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { DateTime } from 'luxon';

import { ScoringService } from 'src/services/scoring.service';
import { FirestoreService } from '../../../../services/firestore.service';
import { UserDataService } from '../../../../services/user-data.service';
import { SettingsService } from '../../../../services/settings.service';

import { FeatureDO } from '../../../models/featureDO';
import { UserDO } from '../../../models/userDO';
import { SettingsDO } from '../../../models/settingsDO';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { delay } from 'lodash';

@Component({
  selector: 'app-release-builder',
  templateUrl: './release-builder.component.html',
  styleUrls: ['./release-builder.component.scss'],
})
export class ReleaseBuilderComponent implements OnInit, OnDestroy {
  /* Form Setup */
  processing = false;

  releasesForm = this.fb.group({
    releaseName: [{ value: null, disabled: true }, [Validators.required]],
    releaseDate: [{ value: null, disabled: true }, [Validators.required]],
    releaseValue: { value: null, disabled: true },
  });

  userDataSubscription: Subscription;
  domainReleases: Subscription;
  userData: any;

  minDate: string;

  settings: SettingsDO;
  userSettingsSubscription: Subscription;
  releasesArray: [];
  settingsReleases: any;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private router: Router,
    private fs: FirestoreService,
    public snackBar: MatSnackBar,
    private userDataService: UserDataService
  ) {
    this.settings = new SettingsDO();
    // this.userDataSubscription = this.userDataService.getUserData().subscribe((user) => {
    //   this.userData = user;
    //   this.domainReleases = this.fs
    //     .getReleaseFromDomain(this.userData.settings.domainId)
    //     .subscribe((res: ReleaseDO[]) => {
    //       this.releases = res;
    //     });
    // });
  }

  ngOnInit() {
    this.minDate = DateTime.local().toString();
  }

  onSubmit() {
    this.processing = true;
    // console.log(this.releasesForm.value);
    // this.settingsReleases = this.settings['0'].releases;
    // this.settingsReleases = this.releasesForm.value;

    // if (this.releasesForm.valid) {
    //   this.settingsReleases.releaseCreatedDate = DateTime.local().toString();
    //   // this.releasesArray.push(...this.settingsReleases);
    //   // this.settings.releases.releaseDate = this..releaseDate;
    //   this.fs.updateSettings(this.settings['0']);
    //   this.userDataService.refreshData();
    //   this.processing = false;
    //   this.snackBar.open('Release Added', '', {
    //     duration: 3000,
    //   });
    // } else if (this.releasesForm.invalid) {
    //   this.snackBar.open('Please fix the errors on your release information', 'Close', {
    //     duration: 3000,
    //   });
    // }
  }

  ngOnDestroy() {
    // this.userSettingsSubscription.unsubscribe();
  }
}
