import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { FeatureDO } from 'src/app/models/featureDO';
import { SettingsDO } from 'src/app/models/settingsDO';
import { UserDO } from 'src/app/models/userDO';
import { ReleaseDO } from 'src/app/models/releaseDO';
import { ProdSurveyDO } from 'src/app/models/surveys/prodSurvey';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  private featureCollection: AngularFirestoreCollection<FeatureDO>;
  private settingsCollection: AngularFirestoreCollection<SettingsDO>;
  private userCollection: AngularFirestoreCollection<UserDO>;
  private releaseCollection: AngularFirestoreCollection<ReleaseDO>;

  private surveyCollection: AngularFirestoreCollection<ProdSurveyDO>;

  features$: Observable<FeatureDO[]>;
  feature$: Observable<FeatureDO[]>;
  settings$: Observable<SettingsDO[]>;
  user$: Observable<UserDO[]>;
  release$: Observable<ReleaseDO[]>;

  constructor(private afs: AngularFirestore) {
    this.featureCollection = afs.collection<FeatureDO>('features');
    // this.features$ = this.featureCollection.valueChanges();

    this.settingsCollection = afs.collection<SettingsDO>('settings');
    // this.settings$ = this.settingsCollection.valueChanges();

    this.userCollection = afs.collection<UserDO>('user');
    // this.user$ = this.userCollection.valueChanges();

    this.releaseCollection = afs.collection<ReleaseDO>('release');

    this.surveyCollection = afs.collection<ProdSurveyDO>('responses');
  }

  /* -----FEATURES----- */

  addFeature(feature: FeatureDO) {
    this.featureCollection.doc(feature.id).set(feature);
  }

  updateFeature(feature: FeatureDO) {
    this.featureCollection.doc(feature.id).update(feature);
  }

  getFeature(featureId: string) {
    this.featureCollection = this.afs.collection<FeatureDO>('features', (ref) =>
      ref.where('id', '==', featureId)
    );
    return (this.feature$ = this.featureCollection.valueChanges());
  }

  getFeatures(user: any): Observable<any> {
    this.featureCollection = this.afs.collection<FeatureDO>('features', (ref) =>
      ref.where('domainId', '==', user.domainId)
    );
    return (this.features$ = this.featureCollection.valueChanges());
  }

  getPublicRoadmapFeatures(domain: any): Observable<any> {
    this.featureCollection = this.afs.collection<FeatureDO>('features', (ref) =>
      ref.where('domainId', '==', domain)
    );
    return (this.features$ = this.featureCollection.valueChanges());
  }

  removeFeature(featureId: string) {
    return this.featureCollection.doc(featureId).delete();
  }

  /* ----- END FEATURES ----- */

  /* ----- SETTINGS ----- */

  addSettings(settings: any) {
    this.settingsCollection.doc(settings.domainId).set(Object.assign({}, settings));
  }

  updateSettings(settings: any) {
    return this.settingsCollection.doc(settings.domainId).update(Object.assign({}, settings));
  }

  getSettingsFromDomain(domain: string): Observable<any> {
    this.settingsCollection = this.afs.collection<SettingsDO>('settings', (ref) =>
      ref.where('domain', '==', domain)
    );
    return (this.settings$ = this.settingsCollection.valueChanges());
  }

  getSettings(user: any): Observable<any> {
    this.settingsCollection = this.afs.collection<SettingsDO>('settings', (ref) =>
      ref.where('domainId', '==', user.domainId)
    );
    return (this.settings$ = this.settingsCollection.valueChanges());
    // return this.afs
    //   .collection('settings', (ref) => ref.where('domainId', '==', user.domainId))
    //   .valueChanges();
  }

  /* -----END SETTINGS----- */

  /* -----USER----- */
  // TODO: Address why the userDO when built from primitive keys drops the email field.

  addUser(user: UserDO) {
    this.userCollection.doc(user.userId).set(Object.assign({}, user));
  }

  updateUser(user: UserDO) {
    this.userCollection.doc(user.userId).update(Object.assign({}, user));
  }

  getUser(userId: string): Observable<any> {
    this.userCollection = this.afs.collection<UserDO>('user', (ref) =>
      ref.where('userId', '==', userId)
    );
    return (this.user$ = this.userCollection.valueChanges());
  }

  getDomainUsers(domainId: string): Observable<any> {
    this.userCollection = this.afs.collection<UserDO>('user', (ref) =>
      ref.where('domainId', '==', domainId)
    );
    return (this.user$ = this.userCollection.valueChanges());
  }

  /* ----- END USER ----- */

  /* ----- Releases ----- */

  addRelease(settings: any) {
    this.releaseCollection.doc(settings.domainId).set(Object.assign({}, settings));
  }

  updateRelease(settings: any) {
    return this.releaseCollection.doc(settings.domainId).update(Object.assign({}, settings));
  }

  getReleaseFromDomain(domain: string): Observable<ReleaseDO[]> {
    this.releaseCollection = this.afs.collection<ReleaseDO>('settings', (ref) =>
      ref.where('domain', '==', domain)
    );
    return (this.release$ = this.releaseCollection.valueChanges());
  }

  getRelease(user: any): Observable<any> {
    this.releaseCollection = this.afs.collection<ReleaseDO>('settings', (ref) =>
      ref.where('domainId', '==', user.domainId)
    );
    return (this.release$ = this.releaseCollection.valueChanges());
  }

  /* ----- END Releases ----- */

  /* ----- Survey Responses ----- */

  addSurveyResponse(survey: ProdSurveyDO) {
    console.log(survey);
    this.surveyCollection.doc(survey.respondentId).set(Object.assign({}, survey));
  }

  /* ----- END Survey Responses ----- */
}
