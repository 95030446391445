import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';

@Pipe({
  name: 'publicComments',
})
export class PublicCommentsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const origVal = value;
    const origArgs = args;
    if (origArgs.role !== 'admin') {
      const filteredValues = filter(origVal, (v) => v.restricted !== true);
      return filteredValues;
    } else if (origArgs.role === 'admin') {
      return origVal;
    }
  }
}
