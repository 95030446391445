import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { ScoringFormComponent } from './landing/scoring-form/scoring-form.component';
import { SettingsComponent } from './landing/settings/settings.component';
import { ScoreResultComponent } from './landing/score-result/score-result.component';
import { AnalyticsComponent } from './landing/analytics/analytics.component';
import { LoginComponent } from './login/login.component';
import { UserResolver } from '../services/user.resolver';
import { FeatureListComponent } from './landing/feature-list/feature-list.component';
import { HomeComponent } from './landing/home/home.component';
import { RoleResolver } from '../services/role.resolver';
import { FeatureDetailComponent } from './landing/feature-list/feature-detail/feature-detail.component';
import { FeatureRequestComponent } from './external-facing/feature-request/feature-request.component';
import { PublicRoadmapComponent } from './external-facing/public-roadmap/public-roadmap.component';
import { AuthGuard } from 'src/services/auth.guard';
import { DisabledResolver } from 'src/services/disabled-user.resolver';
import { PublicDetailComponent } from './external-facing/public-detail/public-detail.component';
import { ProductSurveyComponent } from './external-facing/surveys/product-survey/product-survey.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/:domain', component: LoginComponent },
  { path: ':domain/request', component: FeatureRequestComponent },
  { path: ':domain/roadmap', component: PublicRoadmapComponent },
  { path: 'detail/:domain/:id', component: PublicDetailComponent },
  { path: 'survey/product', component: ProductSurveyComponent },
  {
    path: 'app',
    component: LandingComponent,
    canActivate: [AuthGuard],
    resolve: {
      disabled: DisabledResolver,
    },
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
          { path: 'score', component: ScoringFormComponent },
          { path: '', component: HomeComponent },
          {
            path: 'settings',
            component: SettingsComponent,
            // resolve: { role: RoleResolver },
            // data: { expectedRole: 'admin' },
          },
          { path: 'score-result', component: ScoreResultComponent },
          { path: 'analytics', component: AnalyticsComponent },
          { path: 'list', component: FeatureListComponent },
          { path: 'detail/:id', component: FeatureDetailComponent },
        ],
      },
    ],
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
