import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { ScoringService } from '../../../services/scoring.service';
import { FeatureDO } from '../../models/featureDO';
import { UserDataService } from '../../../services/user-data.service';
import { head, last, sortBy } from 'lodash';
import { FirestoreService } from '../../../services/firestore.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SettingsDO } from 'src/app/models/settingsDO';

@Component({
  selector: 'app-score-result',
  templateUrl: './score-result.component.html',
  styleUrls: ['./score-result.component.scss'],
})
export class ScoreResultComponent implements OnInit, OnDestroy {
  valueSubscription: Subscription;
  feature: FeatureDO;
  settings: SettingsDO;
  scoreCategory: string;

  constructor(
    private scoringService: ScoringService,
    private userDataService: UserDataService,
    private fs: FirestoreService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.valueSubscription = this.userDataService.getUserData().subscribe((userData) => {
      this.feature = last(sortBy(userData.features, ['submitDate']));
      this.settings = userData.settings['0'];
      this.scoreCategory = this.feature.featureScore.scoreCategory;
    });
  }

  submitNewFeature() {
    this.snackBar.open('Your feature was submitted.', '', {
      duration: 4000,
    });
    this.router.navigate(['app/score']);
  }

  viewDetails(id: string) {
    this.snackBar.open('Your feature was submitted.', '', {
      duration: 4000,
    });
    this.router.navigate(['app/detail', id]);
  }

  viewList() {
    this.snackBar.open('Your feature was submitted.', '', {
      duration: 4000,
    });
    this.router.navigate(['app/list']);
  }

  removeFeature(featureId: string) {
    this.fs
      .removeFeature(featureId)
      .then((res) => {
        this.router.navigate(['app/score']);
      })
      .then(() => {
        this.snackBar.open(
          'Your feature request has been removed. If you want to resubmit anything new, please do so now.',
          '',
          {
            duration: 4000,
          }
        );
      });
  }

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
  }
}
