import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';
import { FeatureDO } from 'src/app/models/featureDO';
import { SettingsDO } from 'src/app/models/settingsDO';
import { ChartDataService } from 'src/services/chart-data.service';
import { UserDataService } from '../../../../../services/user-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-priority-pie',
  templateUrl: './priority-pie.component.html',
  styleUrls: ['./priority-pie.component.scss'],
})
export class PriorityPieComponent implements OnInit, AfterViewInit {
  chartPie: any = [];

  userSub: Subscription;

  userSettings: SettingsDO;
  featuresData: FeatureDO[];

  constructor(
    private chartDataService: ChartDataService,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    this.userSub = this.userDataService.getUserData().subscribe((data) => {
      this.featuresData = data.features;
      this.userSettings = data.settings;

      if (this.featuresData) {
        this.setChart2(this.featuresData, this.userSettings);
      }
    });
  }

  ngAfterViewInit() {}

  setChart2(features: FeatureDO[], settings?: SettingsDO, timeFrame?: number) {
    const chartData = this.chartDataService.generatePriorityCountData(
      features,
      settings,
      timeFrame
    );
    const data = {
      labels: ['Low', 'Medium', 'High'],
      datasets: [
        {
          data: chartData,
          backgroundColor: ['#ff8787', '#f6d47d', '#60d8ca'],
          fill: true,
        },
      ],
    };
    const options = {
      title: {
        display: true,
        text: 'Feature Priority Breakdown',
      },
    };
    this.chartPie = new Chart('chartPie', {
      type: 'doughnut',
      data: data,
      options: options,
    });
  }
}
