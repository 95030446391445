import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsDO } from '../app/models/settingsDO';
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor() {}

  private settingsSubject = new BehaviorSubject(<SettingsDO>undefined);

  sendSettings(value) {
    this.settingsSubject.next(value);
  }

  getSettings(): Observable<SettingsDO> {
    return this.settingsSubject.asObservable();
  }
}
