export const OfficerTitles = [
  {
    label: 'CEO',
    value: 'ceo',
  },
  {
    label: 'CFO',
    value: 'cfo',
  },
  {
    label: 'CMO',
    value: 'cmo',
  },
  {
    label: 'CPO',
    value: 'cpo',
  },
  {
    label: 'CTO',
    value: 'cto',
  },
  {
    label: 'CIO',
    value: 'cio',
  },
  {
    label: 'CSO',
    value: 'cso',
  },
  {
    label: 'CRO',
    value: 'cro',
  },
];

export const CompanySize = [
  {
    label: '0 - 4',
    value: 1,
  },
  {
    label: '5 - 49',
    value: 2,
  },
  {
    label: '50 - 199',
    value: 3,
  },
  {
    label: '200 - 499',
    value: 4,
  },
  {
    label: '500 - 999',
    value: 5,
  },
  {
    label: '1000 - 4999',
    value: 6,
  },
  {
    label: '5000+',
    value: 7,
  },
];

export const CompanyRevenue = [
  {
    label: '$0 - $100K',
    value: 1,
  },
  {
    label: '$100K - $500K',
    value: 2,
  },
  {
    label: '$500K - $1M',
    value: 3,
  },
  {
    label: '$1M - $5M',
    value: 4,
  },
  {
    label: '$5M - $10M',
    value: 5,
  },
  {
    label: '$10M - $25M',
    value: 6,
  },
  {
    label: '$25M - $100M',
    value: 7,
  },
  {
    label: '$100M - $250M',
    value: 8,
  },
  {
    label: '$250M - $750M',
    value: 9,
  },
  {
    label: '$750M - $1B',
    value: 10,
  },
  {
    label: '$1B+',
    value: 11,
  },
];

export const ProdTeamTitles = [
  {
    label: 'Associate Product Manager',
    value: 1,
  },
  {
    label: 'Product Manager',
    value: 2,
  },
  {
    label: 'Product Director',
    value: 3,
  },
  {
    label: 'VP, Product',
    value: 4,
  },
  {
    label: 'SVP, Product',
    value: 4,
  },
  {
    label: 'MD, Product',
    value: 4,
  },
  {
    label: 'CPO, Product',
    value: 4,
  },
];
