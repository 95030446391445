const tagsStarter = [
  'Base App',
  'UI',
  'UX',
  'Front End',
  'Back End',
  'Server(s)',
  'Mobile',
  'PWA',
  'Notification(s)',
  'Alerts',
  'Settings',
  'Admin',
  'Bug',
];

export const BaseTags = tagsStarter.sort();

const allTags = [
  'Base App',
  'UI',
  'UX',
  'Front End',
  'Back End',
  'Server(s)',
  'Mobile',
  'PWA',
  'Notification(s)',
  'Alerts',
  'Settings',
  'Admin',
  'Bug',
];

export const StarterTags = allTags.sort();
