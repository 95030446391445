export class ProdSurveyDO {
  public respondentId: string;
  public company?: Company;
  public structure?: Structure;
  public respondent?: Respondent;

  protected getPrimitivePropertyKeys(): string[] {
    return ['company', 'structure', 'respondent'];
  }
}

export class Company extends ProdSurveyDO {
  public companyName?: string;
  public companySize?: string;
  public companyRevenue?: string;
  public officers?: string;

  protected getPrimitivePropertyKeys(): string[] {
    return ['comanyName', 'companySize', 'companyRevenue', 'officers'];
  }
}

export class Structure extends ProdSurveyDO {
  public productType?: string;
  public productTeamSize?: number;
  public productReports?: string;
  public productTeamTitles?: string;
  public projectManagement?: string;
  public productOwner?: string;
  public productOwnerType?: string;

  protected getPrimitivePropertyKeys(): string[] {
    return [
      'productType',
      'productTeamSize',
      'productReports',
      'productTeamTitles',
      'projectManagment',
      'productOwner',
      'productOwnerType',
    ];
  }
}

export class Respondent extends ProdSurveyDO {
  public respondentEmail?: string;
  public respondentContact?: string;

  protected getPrimitivePropertyKeys(): string[] {
    return ['respondentEmail', 'respondentContact', 'respondentId'];
  }
}
