import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { FirestoreService } from '../../services/firestore.service';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';

import { ValidateDomainNotTaken } from '../../validators/domain-validator';

import * as uuidv4 from 'uuid/v4';
import { toLower } from 'lodash';
import { MatSnackBar } from '@angular/material';
import { SettingsDO } from '../models/settingsDO';
import { UserDO } from '../models/userDO';
import { BaseTags } from '../../constants/tags-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: null,
  });

  signupForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: null,
    domain: [null, [Validators.required], ValidateDomainNotTaken.createValidator(this.fs)],
  });

  resetForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });

  processing = false;
  mode = 'login';
  company: string;
  returnUrl: string;
  signupType = 'admin';
  settings: SettingsDO;
  newPass = false;
  user: UserDO;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private fs: FirestoreService,
    private snackBar: MatSnackBar
  ) {
    this.settings = new SettingsDO();
    this.user = new UserDO();
  }

  signup() {
    if (this.signupForm.valid) {
      this.processing = true;
      const password = this.signupForm.value.password;
      const domainId = uuidv4();
      const domain = toLower(this.signupForm.value.domain);
      this.user.email = this.signupForm.value.email;
      this.user.domainId = domainId;
      this.user.role = 'admin';
      this.authService
        .signup(this.user.email, password)
        .then((res) => {
          this.user.userId = res.user.uid;
          // const user = {
          //   domainId: domainId,
          //   userId: res.user.uid,
          //   email: this.signupForm.value.email,
          //   role: role,
          // acceptanceLegal: this.signupForm.value.acceptanceLegal,
          // acceptanceMarketing: this.signupForm.value.acceptanceMarketing,
          const baseSettings = {
            domain: domain,
            domainId: domainId,
            adminUserId: res.user.uid,
            publicRoadmapCols: [
              'Parking Lot',
              'Defining',
              'In Development',
              'Released',
              'Archived',
            ],
            tags: BaseTags,
            scoringThresholds: {
              lowbp: 6,
              highbp: 18,
            },
            financial: {
              wacc: 10,
              riskFreeRate: 3,
            },
          };
          this.fs.addUser(this.user);
          this.fs.addSettings(baseSettings);
        })
        .then(() => {
          this.processing = false;
          this.router.navigate(['/app']);
        })
        .catch((err) => {
          this.processing = false;
          this.snackBar.open(err, 'close', {
            duration: 10000,
          });
        });
    }
  }

  login() {
    if (this.loginForm.valid) {
      this.processing = true;
      const email = this.loginForm.value.email;
      const password = this.loginForm.value.password;
      this.authService.afAuth.auth.setPersistence('local');
      this.authService
        .login(email, password)
        .then(() => {
          this.processing = false;
          this.router.navigate(['/app']);
          // this.router.navigate([this.returnUrl] ? [this.returnUrl] : ['/app']);
        })
        .catch((err) => {
          this.processing = false;
          this.snackBar.open(err, 'close', {
            duration: 10000,
          });
        });
    }
  }

  passwordReset() {
    const email = this.resetForm.value.email;
    this.authService.passwordReset(email).then((res) => {
      if (res === true) {
        this.snackBar.open('A reset email has been sent.', '', { duration: 3000 });
      } else {
        this.snackBar.open(
          'There was an error sending you a reset email, please confirm email is correct',
          '',
          { duration: 3000 }
        );
      }
    });
  }

  // TODO: Take Domain info from login param and act on it...
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.company = params['domain'];
        this.signupType = params['type'] ? params['type'] : 'admin';
        this.mode = params['mode'] ? params['mode'] : 'login';
        if (this.company) {
          this.fs.getSettingsFromDomain(this.company).subscribe((res) => {
            console.log(res);
          });
        }
      }
    });
  }

  ngOnDestroy() {}
}
