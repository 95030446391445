import {
  OnInit,
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../../services/auth.service';
import { FirestoreService } from '../../services/firestore.service';
import { UserService } from '../../services/user.service';

import { Subscription } from 'rxjs';
import { UserDO } from '../models/userDO';
import { flatMap, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { SettingsService } from '../../services/settings.service';
import { UserDataService } from '../../services/user-data.service';
import { SettingsDO } from '../models/settingsDO';
import { FeatureDO } from '../models/featureDO';
import { indexOf } from 'lodash';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  processing = false;
  title: string;
  mobileQuery: MediaQueryList;

  user: UserDO;
  settings: SettingsDO;
  userSubscription: Subscription;
  authState: any;
  authRole: string;

  nav = [];

  private _mobileQueryListener: () => void;
  @Output()
  toggleSideNav = new EventEmitter();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private fs: FirestoreService,
    private userSerivce: UserService,
    private userDataService: UserDataService,
    private settingsService: SettingsService,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  toggleMobileNav(nav: MatSidenav) {
    if (this.mobileQuery.matches) {
      nav.toggle();
    }
  }

  signout() {
    this.authService.logout().then(() => {
      this.router.navigate(['/login']);
    });
  }

  generateNav() {
    this.nav = [
      {
        title: 'Home',
        path: '/app',
        click: 'toggleMobileNav(snav)',
        role: ['user', 'admin'],
      },
      {
        title: 'Add Epic',
        path: '/app/score',
        click: 'toggleMobileNav(snav)',
        role: ['user', 'admin'],
      },
      {
        title: 'View Epics',
        path: '/app/list',
        click: 'toggleMobileNav(snav)',
        role: ['user', 'admin'],
      },
      {
        title: 'Analytics',
        path: '/app/analytics',
        click: 'toggleMobileNav(snav)',
        role: ['user', 'admin'],
      },
      {
        title: 'Public Roadmap',
        path: ['/' + this.settings.domain + '/roadmap'],
        click: 'toggleMobileNav(snav)',
        role: ['user', 'admin'],
      },
      {
        title: 'Settings',
        path: '/app/settings',
        click: 'toggleMobileNav(snav)',
        role: ['admin'],
      },
      {
        title: 'Signout',
        path: '/login',
        click: 'signout()',
        role: ['user', 'admin'],
      },
    ];
  }

  checkRole(obj) {
    return indexOf(obj, this.authRole);
  }

  ngOnInit() {
    this.processing = true;
    this.afAuth.authState.subscribe((authSt) => {
      if (authSt) {
        const userAuth = authSt;
        const uid = userAuth.uid;
        this.fs
          .getUser(uid)
          .pipe(
            flatMap((user: UserDO) => {
              this.user = user['0'];
              this.userSerivce.sendUser(this.user);
              this.userDataService.sendRoleData(this.user.role);
              return this.fs
                .getSettings(this.user)
                .pipe(map((settings: SettingsDO) => ({ user, settings })));
            }),
            flatMap(({ user, settings }) => {
              this.settings = settings['0'];
              this.settingsService.sendSettings(settings);
              return this.fs
                .getFeatures(user['0'])
                .pipe(map((features: FeatureDO) => ({ user, settings, features })));
            }),
            map(({ user, settings, features }) => {
              this.userDataService.sendUserData({ user, settings, features });
              this.processing = false;
              return { user, settings, features };
            })
          )
          .subscribe((check) => {
            if (check) {
              this.generateNav();
              this.processing = false;
            }
            // console.log(check);
          });
      }
    });
    if (this.settings) {
      this.title = this.settings.displayName ? this.settings.displayName : this.settings.domain;
    }
  }
  ngOnDestroy() {}
}
