import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder } from '@angular/forms';

import { ScoringService } from 'src/services/scoring.service';
import { FirestoreService } from 'src/services/firestore.service';
import { UserDataService } from 'src/services/user-data.service';

import { SettingsDO } from 'src/app/models/settingsDO';
import { FeatureDO } from 'src/app/models/featureDO';

import { Subscription } from 'rxjs';
import { set } from 'lodash';

@Component({
  selector: 'app-edit-feature',
  templateUrl: './edit-feature.component.html',
  styleUrls: ['./edit-feature.component.scss'],
})
export class EditFeatureComponent implements OnInit {
  editForm = this.fb.group({
    featureDetail: this.fb.group({
      featureName: this.data.featureDetail.featureName,
      featureDescription: this.data.featureDetail.featureDescription,
      featureTags: [this.data.featureDetail.featureTags],
      featureRevenue: this.data.featureDetail.featureRevenue,
      featureCost: this.data.featureDetail.featureCost,
    }),
    featureScore: this.fb.group({
      evidenceScore: this.data.featureScore.evidenceScore,
      impactScore: this.data.featureScore.impactScore,
      scoreCategory: null,
    }),
    publicFeature: this.fb.group({
      // flag: null,
      stage: [this.data.publicFeature.stage],
    }),
  });

  existingTags = this.data.featureDetail.featureTags;
  tags: string[];
  publicRoadmapCols: string[];
  settings: SettingsDO;

  userSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<EditFeatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FeatureDO,
    private fb: FormBuilder,
    private fs: FirestoreService,
    private scoringService: ScoringService,
    private userDataService: UserDataService
  ) {
    this.userSubscription = this.userDataService.getUserData().subscribe((res) => {
      this.settings = res.settings['0'];
      this.tags = this.settings.tags;
      this.publicRoadmapCols = this.settings.publicRoadmapCols;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSliderChange(event: any, slider: string) {
    if (slider === 'evidence') {
      this.data.featureScore.evidenceScore = event.value;
    } else if (slider === 'impact') {
      this.data.featureScore.impactScore = event.value;
    }
    this.data.featureScore.calculatedScore = this.scoringService.reCalcScore(this.data);
    this.data.featureScore.scoreCategory = this.scoringService.reCalcScoreCategory(
      this.data,
      this.settings
    );
  }

  onSubmitChange() {
    if (this.editForm.valid) {
      this.data.featureDetail = this.editForm.value.featureDetail;
      this.data.featureScore.evidenceScore = this.editForm.value.featureScore.evidenceScore;
      this.data.featureScore.impactScore = this.editForm.value.featureScore.impactScore;
      // this.data.publicFeature.flag = this.editForm.value.publicFeature.flag;
      if (this.data.publicFeature) {
        this.data.publicFeature.stage = this.editForm.value.publicFeature.stage;
      } else if (!this.data.publicFeature) {
        set(this.data, 'publicFeature.stage', this.editForm.value.publicFeature.stage);
      }
      this.fs.updateFeature(this.data);
      this.dialogRef.close('success');
    }
  }

  ngOnInit() {}
}
