import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FirestoreService } from 'src/services/firestore.service';
import { OfficerTitles, CompanySize, CompanyRevenue, ProdTeamTitles } from './response-constants';
import { ProdSurveyDO } from 'src/app/models/surveys/prodSurvey';
import * as uuidv4 from 'uuid/v4';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-product-survey',
  templateUrl: './product-survey.component.html',
  styleUrls: ['./product-survey.component.scss'],
})
export class ProductSurveyComponent implements OnInit {
  processing = false;

  source: string;
  rid: string;
  company: string;

  surveyResponse: ProdSurveyDO;
  pO = false;

  /**
   * Constants for Response Sets
   */

  officerTitles = OfficerTitles;
  companySizes = CompanySize;
  companyRevenues = CompanyRevenue;
  prodTeamTitles = ProdTeamTitles;

  /**
   * Constants for Response Sets
   */
  surveyForm = this.fb.group({
    company: this.fb.group({
      companyName: [null, [Validators.required]],
      companySize: [null],
      companyRevenue: [null],
      officers: [null, [Validators.required]],
    }),
    structure: this.fb.group({
      productType: [null, [Validators.required]],
      productTeamSize: [null, [Validators.required]],
      productReports: [null, [Validators.required]],
      productTeamTitles: [null, [Validators.required]],
      projectManagement: [null],
      productOwner: [null],
      productOwnerType: [null],
    }),
    respondent: this.fb.group({
      respondentEmail: [null, [Validators.email]],
      respondentContact: [null],
    }),
  });

  constructor(
    private fb: FormBuilder,
    private fs: FirestoreService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.surveyResponse = new ProdSurveyDO();
    console.log(this.surveyResponse);
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.source = params['src'];
        this.surveyResponse.respondentId = params['rid'] ? params['rid'] : uuidv4();
        this.company = params['company'];
      }
    });
  }

  setPO(value: boolean) {
    this.pO = value;
  }

  onSubmit() {
    if (this.surveyForm.valid) {
      const response = this.surveyForm.value;
      this.surveyResponse.company = response.company;
      this.surveyResponse.structure = response.structure;
      this.surveyResponse.respondent = response.respondent;
      console.log(this.surveyResponse);
      this.fs.addSurveyResponse(this.surveyResponse);
      this.snackBar.open('Thank you, we will reach out based on your preferences.', '', {
        duration: 3000,
      });
      console.log(this.surveyResponse);
    } else {
      this.snackBar.open('Pleasee complete all of the required fields', '', { duration: 3000 });
    }
  }
}
