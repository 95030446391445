import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { EmailDO } from '../app/models/emailDO';
import { catchError, tap } from 'rxjs/operators';
import { inviteTemplate } from '../constants/email-templates';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {}

  // Each different email needs to have their API call paprameters changed. Then the general call structure can execute.
  // constructInviteEmail(email: string, template: string, password: string) {
  //   const data = JSON.stringify({
  //     from: 'support@prodmmd.com',
  //     reply_to: 'support@prodmmd.com',
  //     template_id: 'd-9ed7462048834a89b6435bdc1fecbb9c',
  //     personalizations: [
  //       {
  //         to: [
  //           {
  //             email: email,
  //           },
  //         ],
  //         dynamic_template_data: {
  //           password: password,
  //         },
  //       },
  //     ],
  //   });
  //   return data;
  // }

  sendEmail(email: string, template: string, password?: string): Observable<any> {
    const data = this.buildData(email, template, password);
    console.log('triggering email send call' + data);
    return this.http
      .post(environment.API.sendEmailApi, data, this.httpOptions)
      .pipe(tap((res) => console.log(res)));
  }

  private buildData(email: string, template: string, password?: string) {
    if (template === 'inviteEmail') {
      const templateId = 'd-9ed7462048834a89b6435bdc1fecbb9c';
      const data = {
        from: 'support@prodmmd.com',
        to: email,
        password: password,
        templateId: templateId,
      };
      return data;
    }
  }
}
// private handleError(error: HttpErrorResponse) {
//   if (error.error instanceof ErrorEvent) {
//     // A client-side or network error occurred. Handle it accordingly.
//     console.error('An error occurred:', error.error.message);
//   } else {
//     // The backend returned an unsuccessful response code.
//     // The response body may contain clues as to what went wrong,
//     console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
//   }
//   // return an observable with a user-facing error message
//   // return ('Something bad happened; please try again later.');
// }
