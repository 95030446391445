import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { FirestoreService } from '../../../../services/firestore.service';
import { UserDataService } from '../../../../services/user-data.service';
import { EmailService } from 'src/services/email.service';
import { Subscription } from 'rxjs';
import { UserDO } from 'src/app/models/userDO';
import { MatSnackBar, MatTableDataSource, MatSort } from '@angular/material';

import { without, omitBy, pickBy } from 'lodash';
import { duration } from 'moment';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss'],
})
export class InviteUserComponent implements OnInit, OnDestroy {
  processing = false;
  roleEdit = false;
  inviteForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    role: null,
  });

  @Input() user: UserDO;

  domainUsers: string[];
  updateUser: UserDO;
  userSignup: UserDO;
  usersDataSubscription: Subscription;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['position', 'email', 'role', 'status', 'options'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private authService: AuthService,
    private fs: FirestoreService,
    private fb: FormBuilder,
    private userDataService: UserDataService,
    private snackBar: MatSnackBar,
    private emailService: EmailService
  ) {
    this.userSignup = new UserDO();
    this.updateUser = new UserDO();
  }

  ngOnInit() {
    this.usersDataSubscription = this.fs.getDomainUsers(this.user.domainId).subscribe((res) => {
      this.domainUsers = res;
      this.dataSource = res;
      this.dataSource.sort = this.sort;
    });
  }

  createPassword() {
    const password =
      Math.random()
        .toString(36)
        .substring(2, 10) +
      Math.random()
        .toString(36)
        .substring(2, 10);
    return password;
  }

  onSubmit() {
    this.processing = true;
    if (this.inviteForm.valid) {
      this.userSignup.email = this.inviteForm.value.email;
      this.userSignup.role = this.inviteForm.value.role;
      this.userSignup.domainId = this.user.domainId;
      this.userSignup.tempPass = this.createPassword();
      this.authService
        .signup(this.userSignup.email, this.userSignup.tempPass)
        .then((res) => {
          this.userSignup.userId = res.user.uid;
          this.fs.addUser(this.userSignup);
        })
        .then(() => {
          this.sendEmail(this.userSignup.email, 'inviteEmail', this.userSignup.tempPass);
          this.snackBar.open('The user has been invited and an email was sent!', '', {
            duration: 3000,
          });
        })
        .catch((error) => {
          this.snackBar.open(
            'There was a error in the invite, please try again -' + error,
            'Dismiss'
          );
        });
    }
  }

  // TODO: Need to propogate the flag into guards and access checks.
  disableUser(event) {
    this.processing = true;
    this.updateUser = event;
    this.updateUser.disabled = true;
    this.fs.updateUser(this.updateUser);
    this.processing = false;
  }

  enableUser(event) {
    this.processing = true;
    this.updateUser = event;
    this.updateUser.disabled = false;
    this.fs.updateUser(this.updateUser);
    this.processing = false;
  }

  // TODO: Edit user event completion, the current added user is not editable.
  editUser(event, value) {
    // console.log(event, value);
  }

  // TODO: Need to build the domainId removal system once setup the multiple domainId structure.
  removeUser(event) {
    // const domainId = this.user.domainId;
    // const user = event;
    // const withoutTest = without(user, domainId);
    // const omitbyTest = omitBy(user, (v) => v !== domainId);
    // const pickbyTest = pickBy(user, (v) => v !== domainId);
    this.snackBar.open(
      'Remove is being developed. Please lookout for a future release note. If you need to remove a user, contact us via chat.',
      'Dismiss',
      { duration: 5000 }
    );
  }

  sendEmail(email: string, template: string, password: string) {
    this.emailService.sendEmail(email, template, password).subscribe((res) => console.log(res));
  }

  sendEmailTest(email: string, template: string, password: string) {
    this.emailService
      .sendEmail('geoff@prodmmd.com', 'inviteEmail', 'PASSWORD')
      .subscribe((res) => console.log(res));
  }

  ngOnDestroy() {
    this.usersDataSubscription.unsubscribe();
  }
}
