import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { MaterialModule } from './material/material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgPipesModule } from 'ngx-pipes';
import { MarkdownModule } from 'ngx-markdown';

import { UserResolver } from '../services/user.resolver';
import { RoleResolver } from '../services/role.resolver';
import { AuthGuard } from '../services/auth.guard';
import { DisabledResolver } from 'src/services/disabled-user.resolver';

import { environment } from '../environments/environment';

import { HomeComponent } from './landing/home/home.component';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { ScoringFormComponent } from './landing/scoring-form/scoring-form.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SettingsComponent } from './landing/settings/settings.component';
import { ScoreResultComponent } from './landing/score-result/score-result.component';
import { AnalyticsComponent } from './landing/analytics/analytics.component';
import { FeatureListComponent } from './landing/feature-list/feature-list.component';
import { FeatureTableComponent } from './landing/feature-list/feature-table/feature-table.component';
import { FeatureKanbanComponent } from './landing/feature-list/feature-kanban/feature-kanban.component';
import { LoadingComponent } from './common/loading/loading.component';
import { TagBuilderComponent } from './landing/settings/tag-builder/tag-builder.component';
import { ReleaseBuilderComponent } from './landing/settings/release-builder/release-builder.component';
import { FeatureRequestComponent } from './external-facing/feature-request/feature-request.component';
import { ReleaseTableComponent } from './landing/settings/release-table/release-table.component';
import { InviteUserComponent } from './landing/settings/invite-user/invite-user.component';
import { FeatureDetailComponent } from './landing/feature-list/feature-detail/feature-detail.component';
import { PublicRoadmapComponent } from './external-facing/public-roadmap/public-roadmap.component';
import { PublicFeaturePipe } from '../pipes/public-feature.pipe';
import { RoadmapLabelsComponent } from './landing/settings/roadmap-labels/roadmap-labels.component';
import { PublicReleasesComponent } from './external-facing/public-releases/public-releases.component';
import { FabButtonComponent } from './common/fab-button/fab-button.component';
import { PriorityPieComponent } from './landing/analytics/charts/priority-pie/priority-pie.component';
import { EditFeatureComponent } from './common/edit-feature/edit-feature.component';
import { VoteComponent } from './external-facing/public-roadmap/vote/vote.component';
import { LoginDialogComponent } from './common/login-dialog/login-dialog.component';
import { FeatureCommentComponent } from './common/feature-comment/feature-comment.component';
import { PublicDetailComponent } from './external-facing/public-detail/public-detail.component';
import { UserInfoComponent } from './landing/settings/user-info/user-info.component';
import { StatusTilesComponent } from './landing/analytics/charts/status-tiles/status-tiles.component';
import { StackbarTsComponent } from './landing/analytics/charts/stackbar-ts/stackbar-ts.component';
import { PublicCommentsPipe } from '../pipes/public-comments.pipe';
import { FeatureCommentDialogComponent } from './common/feature-comment-dialog/feature-comment-dialog.component';
import { ReleaseManagerComponent } from './landing/release-manager/release-manager.component';
import { FooterComponent } from './common/footer/footer.component';
import { FeatureKanbanStagesComponent } from './landing/feature-list/feature-kanban/feature-kanban-stages/feature-kanban-stages.component';
// tslint:disable-next-line:max-line-length
import { FeatureKanbanPriorityComponent } from './landing/feature-list/feature-kanban/feature-kanban-priority/feature-kanban-priority.component';
import { CompanyInfoComponent } from './landing/settings/company-info/company-info.component';
import { ProductSurveyComponent } from './external-facing/surveys/product-survey/product-survey.component';
import { FinancialInfoComponent } from './landing/settings/financial-info/financial-info.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    ScoringFormComponent,
    SettingsComponent,
    ScoreResultComponent,
    AnalyticsComponent,
    LoginComponent,
    FeatureListComponent,
    FeatureTableComponent,
    FeatureKanbanComponent,
    LoadingComponent,
    HomeComponent,
    TagBuilderComponent,
    ReleaseBuilderComponent,
    FeatureRequestComponent,
    ReleaseTableComponent,
    InviteUserComponent,
    FeatureDetailComponent,
    PublicRoadmapComponent,
    PublicFeaturePipe,
    RoadmapLabelsComponent,
    PublicReleasesComponent,
    FabButtonComponent,
    PriorityPieComponent,
    EditFeatureComponent,
    VoteComponent,
    LoginDialogComponent,
    FeatureCommentComponent,
    PublicDetailComponent,
    UserInfoComponent,
    StatusTilesComponent,
    StackbarTsComponent,
    PublicCommentsPipe,
    FeatureCommentDialogComponent,
    ReleaseManagerComponent,
    FooterComponent,
    FeatureKanbanStagesComponent,
    FeatureKanbanPriorityComponent,
    CompanyInfoComponent,
    ProductSurveyComponent,
    FinancialInfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    DragDropModule,
    AngularFireModule.initializeApp(environment.firestore),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgPipesModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
  ],
  entryComponents: [EditFeatureComponent, LoginDialogComponent, FeatureCommentDialogComponent],
  providers: [RoleResolver, AuthGuard, DisabledResolver],
  bootstrap: [AppComponent],
})
export class AppModule {}
