import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserDataService } from './user-data.service';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class RoleResolver implements Resolve<any> {
  userRole = '';
  constructor(
    private authService: AuthService,
    private userDataService: UserDataService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expectedRole = route.data.expectedRole;
    this.userDataService.getUserData().subscribe((user) => {
      if (user) {
        this.userRole = user.user['0'].role ? user.user['0'].role : 'user';
        if (this.userRole === expectedRole) {
          return true;
        } else if (this.userRole !== expectedRole) {
          this.snackBar.open('You do not have permissions to access this functionality', '', {
            duration: 4000,
          });
          this.router.navigate(['app']);
          return false;
        }
      }
    });
  }
}
