import { Component, OnInit, Inject } from '@angular/core';
import { FeatureDO } from 'src/app/models/featureDO';
import { Observable } from 'rxjs';
import { CommentService } from 'src/services/comment.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserDO } from 'src/app/models/userDO';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-feature-comment-dialog',
  templateUrl: './feature-comment-dialog.component.html',
  styleUrls: ['./feature-comment-dialog.component.scss'],
})
export class FeatureCommentDialogComponent implements OnInit {
  commentForm = this.fb.group({
    comment: [null, [Validators.required]],
    restricted: false,
  });

  feature: FeatureDO;
  user: UserDO;
  commentsObs: Observable<any>;
  comments: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public fcData: any,
    private commentService: CommentService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FeatureCommentDialogComponent>
  ) {
    this.feature = this.fcData.feature;
    this.user = this.fcData.user['0'];
  }

  ngOnInit() {
    this.commentService.getPublicFeatureComments(this.feature).subscribe((res) => {
      this.comments = res;
    });
  }

  // this.totalVotes = this.votes.pipe(
  //   map((arr) => {
  //     const ratings = arr.map((v) => v.value);
  //     return ratings.length ? ratings.reduce((total, val) => total + val) : 'no votes yet';
  //   })
  // );

  sendComment() {
    const comment = this.commentForm.value.comment;
    const restricted = this.commentForm.value.restricted;
    this.commentService.makeComment(this.feature, this.user, comment, restricted).then(() => {
      this.commentForm.reset();
      this.snackBar.open('Comment sent', '', { duration: 2000 });
    });
  }
}
