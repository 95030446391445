import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatAutocomplete,
} from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseTags } from '../../../../constants/tags-constants';
import { FirestoreService } from '../../../../services/firestore.service';
import { SettingsDO } from '../../../models/settingsDO';
import { SettingsService } from '../../../../services/settings.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-tag-builder',
  templateUrl: './tag-builder.component.html',
  styleUrls: ['./tag-builder.component.scss'],
})
export class TagBuilderComponent implements OnInit, OnDestroy {
  // mat tag options
  visible = true;
  selectable = false;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;

  tags: string[];
  allTags: string[] = BaseTags;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  // Settings subscription
  settings: SettingsDO;

  userSettingsSubscription: Subscription;

  constructor(
    private fs: FirestoreService,
    private settingsService: SettingsService,
    private snackbar: MatSnackBar
  ) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice()))
    );
    this.userSettingsSubscription = this.settingsService.getSettings().subscribe((userSettings) => {
      this.settings = userSettings['0'];
      this.tags = this.settings.tags ? this.settings.tags : BaseTags;
    });
  }

  ngOnInit() {}

  add(event: MatChipInputEvent): void {
    // Add tag only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our tag
      if ((value || '').trim()) {
        this.tags.push(value.trim());
        this.settings.tags = this.tags;
        this.fs.updateSettings(this.settings);
        this.snackbar.open('Tag added', 'Dismiss', { duration: 2000 });
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.tagCtrl.setValue(null);
    }
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.settings.tags = this.tags;
      this.fs.updateSettings(this.settings);
      this.snackbar.open('Tag removed', 'Dismiss', { duration: 2000 });
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter((tag) => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnDestroy() {}
}
