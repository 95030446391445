import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-kanban',
  templateUrl: './feature-kanban.component.html',
  styleUrls: ['./feature-kanban.component.scss'],
})
export class FeatureKanbanComponent implements OnInit {
  displayType = 'roadmap';
  constructor() {}

  ngOnInit() {}
}
