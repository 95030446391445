import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';

@Pipe({
  name: 'publicFeature',
})
export class PublicFeaturePipe implements PipeTransform {
  transform(value: any, arg?: string): any {
    const origValues = value;
    const filteredValues = filter(origValues, (x) => {
      return (
        (x.publicFeature ? x.publicFeature.flag : false) === true && x.publicFeature.stage === arg
      );
    });
    // const filteredValues = filter(origValues, ['value.publicFeature.flag', arg]);
    return filteredValues;
  }
}
