import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import { reverse, mean, sortBy } from 'lodash';
import { SettingsDO } from '../app/models/settingsDO';
import { FeatureDO } from '../app/models/featureDO';

@Injectable({
  providedIn: 'root',
})
export class ChartDataService {
  constructor() {}

  generateLabels(features: FeatureDO[]) {
    const labelsArray = [];
    features.forEach((res: FeatureDO) => {
      labelsArray.push(res.featureDetail.featureName);
    });
    return labelsArray;
  }

  generateScoreDataXY(features: FeatureDO[]) {
    return features.map((res: FeatureDO) => {
      return {
        t: moment(res.submitDate),
        y: res.featureScore.calculatedScore,
      };
    });
  }

  generateScoreBreakdownData(features: FeatureDO[]) {
    const dataArray = [];
    features.forEach((res: FeatureDO) => {
      const data = {
        x: res.featureScore.evidenceScore,
        y: res.featureScore.impactScore,
      };
      dataArray.push(data);
    });
    return dataArray;
  }

  // Keep - being userd.
  generatePriorityCountData(features: FeatureDO[], settings?: SettingsDO, timeFrame?: number) {
    const userSettings = settings['0'];
    const dt = DateTime.local();
    const tf = timeFrame ? timeFrame : 6;
    const low = features
      .filter(
        (featureTime) =>
          DateTime.fromISO(featureTime.submitDate) >=
          DateTime.fromISO(featureTime.submitDate).minus({ months: tf })
      )
      .filter(
        (feature) => feature.featureScore.calculatedScore <= userSettings.scoringThresholds.lowbp
      )
      .map((fres) => fres.featureScore.calculatedScore)
      .reduce((acc, score, i) => acc + 1, 0);
    const medium = features
      .filter(
        (featureTime) =>
          DateTime.fromISO(featureTime.submitDate) >=
          DateTime.fromISO(featureTime.submitDate).minus({ months: tf })
      )
      .filter(
        (feature) =>
          feature.featureScore.calculatedScore > userSettings.scoringThresholds.lowbp &&
          feature.featureScore.calculatedScore < userSettings.scoringThresholds.highbp
      )
      .map((fres) => fres.featureScore.calculatedScore)
      .reduce((acc, score, i) => acc + 1, 0);
    const high = features
      .filter(
        (featureTime) =>
          DateTime.fromISO(featureTime.submitDate) >=
          DateTime.fromISO(featureTime.submitDate).minus({ months: tf })
      )
      .filter(
        (feature) => feature.featureScore.calculatedScore >= userSettings.scoringThresholds.highbp
      )
      .map((fres) => fres.featureScore.calculatedScore)
      .reduce((acc, score, i) => acc + 1, 0);
    return [low, medium, high];
  }

  // TODO: Using for stack bar - still needs work
  // generatePriorityCountMonthlyData(
  //   features: FeatureDO[],
  //   settings?: SettingsDO,
  //   timeFrame?: number
  // ) {
  //   const returnArray = [];
  //   const userSettings = settings['0'];
  //   const tf = timeFrame ? timeFrame : 3;
  //   for (let i = 0; i < tf; i++) {
  //       features
  //         .filter(
  //           (featureTime) =>
  //             DateTime.fromISO(featureTime.submitDate) >=
  //               DateTime.fromISO(featureTime.submitDate).minus({ months: i }) &&
  //             DateTime.fromISO(featureTime.submitDate) <=
  //               DateTime.fromISO(featureTime.submitDate).minus({ months: i++ })
  //         )
  //         .filter(
  //           (feature) => feature.featureScore.calculatedScore <= userSettings.scoringThresholds.low
  //         )
  //         .map((fres) => fres.featureScore.calculatedScore);
  //     );
  //   }
  //   returnArray.push(...low);
  //   console.log(returnArray);
  //   return returnArray;
  // }

  // Needs to be adjusted to datetime vs. moment
  generateDates(features: FeatureDO[]) {
    const dateArray = [];
    features.forEach((res) => {
      const jsdate = moment(res.submitDate);
      dateArray.push(moment(jsdate).format('MMM Do YY'));
    });
    return dateArray;
  }

  // This is working and complete. Returns array of months prior based on timeframe provided.
  previousNMos(timeframeMos: number) {
    const timeMos = [];
    for (let i = 0; i < timeframeMos; i++) {
      timeMos.push(
        DateTime.local()
          .minus({ months: i })
          .toFormat('LLLyy')
      );
    }
    return reverse(timeMos);
  }
}
