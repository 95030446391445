import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { FeatureDO } from 'src/app/models/featureDO';
import { SettingsDO } from 'src/app/models/settingsDO';
import { ChartDataService } from 'src/services/chart-data.service';
import { UserDataService } from 'src/services/user-data.service';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-stackbar-ts',
  templateUrl: './stackbar-ts.component.html',
  styleUrls: ['./stackbar-ts.component.scss'],
})
export class StackbarTsComponent implements OnInit, OnDestroy, AfterViewInit {
  stackBar: any = [];

  @Input() timeFrame: number;

  featuresData: FeatureDO[];
  userSettings: SettingsDO;
  primarySub: Subscription;

  priorityMap: any;
  dateMap: any;

  constructor(
    private chartDataService: ChartDataService,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    this.primarySub = this.userDataService.getUserData().subscribe((data) => {
      this.featuresData = data.features;
      this.userSettings = data.settings;

      if (this.featuresData) {
        this.setChart(this.featuresData, this.userSettings, this.timeFrame);
      }
    });
  }

  ngAfterViewInit() {}

  buildChartData() {}

  setChart(features: FeatureDO[], settings?: SettingsDO, timeFrame?: number) {
    const labelSeries = this.chartDataService.previousNMos(3);
    // const chart3Data = this.chartDataService.generatePriorityCountMonthlyData(
    //   features,
    //   settings,
    //   timeFrame
    // );
    // const chart1Time = this.chartDataService.generateDates(features);
    const data = {
      labels: labelSeries,
      datasets: [
        {
          label: 'Low',
          data: [10, 25, 15],
          backgroundColor: '#ff8787',
          fill: true,
        },
        {
          label: 'Medium',
          data: [10, 25, 15],
          backgroundColor: '#f6d47d',
          fill: true,
        },
        {
          label: 'High',
          data: [10, 25, 15],
          backgroundColor: '#60d8ca',
          fill: true,
        },
      ],
    };
    const options = {
      title: {
        display: true,
        text: 'Total Submissions by Type',
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
    };

    this.stackBar = new Chart('stackBar', {
      type: 'bar',
      data: data,
      options: options,
    });
  }

  ngOnDestroy() {
    this.primarySub.unsubscribe();
  }
}
