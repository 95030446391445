import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { SettingsDO } from 'src/app/models/settingsDO';
import { UserDO } from 'src/app/models/userDO';
import { UserDataService } from 'src/services/user-data.service';
import { FormBuilder } from '@angular/forms';
import { FirestoreService } from 'src/services/firestore.service';
import { isEmpty } from 'lodash';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-financial-info',
  templateUrl: './financial-info.component.html',
  styleUrls: ['./financial-info.component.scss'],
})
export class FinancialInfoComponent implements OnInit {
  userDataSubscription: Subscription;
  settings: SettingsDO;
  user: UserDO;

  financialForm = this.fb.group({});

  constructor(
    private userDataService: UserDataService,
    private fb: FormBuilder,
    private fs: FirestoreService,
    private snackBar: MatSnackBar
  ) {
    this.settings = new SettingsDO();
    this.userDataSubscription = this.userDataService.getUserData().subscribe((user) => {
      this.settings = user.settings['0'];
      // Executing as a rollover for existing users.
      if (user) {
        console.log(this.settings);
        this.financialForm.patchValue(this.settings.financial);
      }
    });
  }

  ngOnInit() {
    this.financialForm = this.fb.group({
      wacc: this.settings.financial.wacc,
      riskFreeRate: this.settings.financial.riskFreeRate,
    });
  }

  onSubmit() {
    if (this.financialForm.valid) {
      this.settings.financial.wacc = this.financialForm.value.wacc;
      this.settings.financial.riskFreeRate = this.financialForm.value.riskFreeRate;
      this.fs
        .updateSettings(this.settings)
        .then(() => {
          this.snackBar.open('Your financial metrics have been saved', '', { duration: 3000 });
        })
        .catch((err) => {
          this.snackBar.open('There was an error' + err, '', { duration: 3000 });
        });
    }
  }

  /**
   * If the user already exists and does not have the financial defaults, then assign them to the DB.
   */
  // createDefaults() {
  //   this.settings.financial.wacc = 10;
  //   this.settings.financial.riskFreeRate = 3;
  //   this.financialForm.patchValue(this.settings.financial);
  //   console.log(this.settings);
  // }
}
