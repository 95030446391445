import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../../../services/firestore.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FeatureDO } from '../../models/featureDO';
import { lowerCase } from 'lodash';
import { SettingsDO } from '../../models/settingsDO';
import { VotingService } from '../../../services/voting.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoginDialogComponent } from 'src/app/common/login-dialog/login-dialog.component';
import { AuthService } from 'src/services/auth.service';
import { UserDO } from 'src/app/models/userDO';
import { FeatureCommentDialogComponent } from 'src/app/common/feature-comment-dialog/feature-comment-dialog.component';

@Component({
  selector: 'app-public-roadmap',
  templateUrl: './public-roadmap.component.html',
  styleUrls: ['./public-roadmap.component.scss'],
})
export class PublicRoadmapComponent implements OnInit {
  processing = false;
  panelOpenState = false;
  domainFromUrl: string;
  domainId: string;
  authUserId: string;
  isAnonymous;

  featuresSubscription: Subscription;
  features: FeatureDO[] = [];
  settings: SettingsDO;
  user: UserDO;
  roadmapCols: [] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fs: FirestoreService,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private votingService: VotingService,
    public dialog: MatDialog
  ) {
    this.settings = new SettingsDO();
    this.user = new UserDO();

    this.processing = true;
    this.route.params.subscribe((params) => {
      this.domainFromUrl = params['domain'];
      if (this.domainFromUrl) {
        this.fs
          .getSettingsFromDomain(lowerCase(this.domainFromUrl))
          .pipe(
            switchMap((res) => {
              this.settings = res;
              this.domainId = res['0'].domainId;
              this.roadmapCols = res['0'].publicRoadmapCols;
              return this.fs.getPublicRoadmapFeatures(this.domainId);
            })
          )
          .subscribe((res) => {
            this.features = res;
            this.processing = false;
          });
      }
    });

    this.afAuth.authState.subscribe((user) => {
      if (user) {
        const userAuth = user;
        this.isAnonymous = user.isAnonymous;
        this.authUserId = userAuth.uid;
        this.fs.getUser(this.authUserId).subscribe((res) => {
          this.user = res;
        });
      } else {
        this.openDialog('login');
      }
    });
  }

  ngOnInit() {}

  // Opens login dialog for any user who is not current authed.

  openDialog(type: 'login' | 'signup'): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '500px',
      data: {
        message:
          // tslint:disable-next-line:max-line-length
          'You can login to your existing account, signup or continue as a guest. If you login you will be able to vote and comment on the features listed.',
        mode: type,
        domainId: this.domainId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.snackBar.open('You are now logged-in and can place votes & comments', '', {
          duration: 3000,
        });
      } else if (result === 'anon') {
        this.snackBar
          .open(
            'Please consider signing up so you can vote and comment on features listed.',
            'Signup',
            {
              duration: 8000,
            }
          )
          .onAction()
          .subscribe(() => {
            this.openDialog('signup');
          });
        // this.afAuth.auth.signInAnonymously().catch((err) => {
        //   this.snackBar.open(err, '', { duration: 3000 });
        // });
      }
    });

    dialogRef.backdropClick().subscribe((result) => {
      this.snackBar
        .open(
          'Please consider signing up so you can vote and comment on features listed.',
          'Signup',
          {
            duration: 8000,
          }
        )
        .onAction()
        .subscribe(() => {
          this.openDialog('signup');
        });
    });
  }

  logOut() {
    this.authService.logout();
  }

  navPublicDetail(domain: string, id: string) {
    this.router.navigate(['detail', domain, id]);
  }

  // Opens Chat dialog for any feature selected.

  openFeatureChatDialog(feature: FeatureDO, user: UserDO): void {
    const dialogRef = this.dialog.open(FeatureCommentDialogComponent, {
      width: '700px',
      height: '90%',
      data: {
        domainId: this.domainId,
        feature: feature,
        user: user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        // this.snackBar.open('You are now logged-in and can place votes & comments', '', {
        //   duration: 3000,
        // });
      }
    });

    dialogRef.backdropClick().subscribe((result) => {
      // this.snackBar
      //   .open(
      //     'Please consider signing up so you can vote and comment on features listed.',
      //     'Signup',
      //     {
      //       duration: 8000,
      //     }
      //   )
      //   .onAction()
      //   .subscribe(() => {
      //     this.openDialog('signup');
      //   });
    });
  }
}
